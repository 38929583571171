import React, { useState } from "react";
import { useSelector } from "react-redux";
import Style from "./SearchBar.module.css";
import Theme from "../../theme.module.css";
import { useTranslation } from "react-i18next";


export default function SearchBar({mobileSearchBarActive, setMobileSearchBarActive}) {

    const {isMobile} = useSelector(state => state.mobile);
    const {t, i18n} = useTranslation('common');
    const [searchTerm, setSearchTerm] = useState('');
    

    function handleSearch(event) {
        event.preventDefault();
        const sanitizedSearchTerm = searchTerm.replace(/[^a-zA-Z0-9 ]/g, "");

        // forward to catalog page with search term as query string
        window.location.href = '/catalog?search=' + sanitizedSearchTerm;
    }

    return (
        <>
        {!isMobile ? (
            <div className={`${Style.searchBar} ${Theme.flexRow}`}>
                <form onSubmit={handleSearch}>
                    <input type="text" id="searchBar" placeholder={t('header.search')} value={searchTerm} onChange={(event) => {setSearchTerm(event.target.value)}} />
                    <button type="submit"><img src={require('../../assets/img/icon_magnify.svg').default} className={Style.icons} alt="my-account"/></button>
                </form>
            </div>
        ) : (
            <>
                <img src={require('../../assets/img/icon_magnify.svg').default} className={Style.icons} alt="my-account" onClick={() => setMobileSearchBarActive(!mobileSearchBarActive)}/>
                <div className={`${Style.searchBar} ${Style.mobileCont} ${Theme.flexRow} ${mobileSearchBarActive && Style.mobileActive}`}>
                    <form onSubmit={handleSearch}>
                        <input type="text" id="searchBar" placeholder={t('header.search')} value={searchTerm} onChange={(event) => {setSearchTerm(event.target.value)}} />
                        <button type="submit"><img src={require('../../assets/img/icon_magnify.svg').default} className={Style.icons} alt="my-account"/></button>
                    </form>
                </div>
            </>
        )}
        </>
    );
}