import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import Content from "../layouts/Content";
import ContactForm from "../features/contactForm/ContactForm";
import Style from "./Contact.module.css";
import Theme from "../theme.module.css";
import Faqs from "../features/faqs/Faqs";


export default function Contact() {

    const {t, i18n} = useTranslation('common');
    const {isMobile} = useSelector(state => state.mobile);
    const {localeCode} = useSelector(state => state.internationalisation);
    const dotComLocales = ['US', 'UK', 'NZ', 'AU'];

    return ( 
        <div className={`${Style.contactPage} ${isMobile && Style.mobile}`}>
            <Content>   
                    <h1>{t('faqs.before-getting-in-touch')}</h1>
                    <Faqs />

                    <h1>{t('contact.title')}</h1>
                    <ContactForm />

                    <div className={Style.contactDetails}>
                        <a href="mailto:info@theraceworks.com">{t('contact.email')}: info@theraceworks.com</a>
                        {dotComLocales.includes(localeCode) ? (
                            <p>Redirect Marketing Ltd, Norman House, 15 Stephenson Way, Three Bridges, Crawley, RH10 1TN, United Kingdom</p>
                        ) : (
                            <p>Redirect Marketing Ltd, Hoevenseweg 41 NL-4877 LA Etten-Leur, Netherlands</p>
                        )}
                    </div>
            </Content>
        </div>
    );
}