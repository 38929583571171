import React, { useEffect, useState } from "react";
import Content from '../../layouts/Content';
import Style from './CatalogHero.module.css';
import { teamsDrivers } from '../../data/teams-drivers';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet-async';
import './TeamHero.css';


export default function CatalogHero({category}) {

    const [driverTeam, setDriverTeam] = useState(null);
    const [heroImage, setHeroImage] = useState(null);
    const [teamLogo, setTeamLogo] = useState(null);
    const [pageTitle, setPageTitle] = useState(null);
    const [pageDesc, setPageDesc] = useState(null);
    const {isMobile} = useSelector(state => state.mobile);
    const {t, i18n} = useTranslation('common');
    
    
    useEffect(() => {
        let driver = teamsDrivers.find((driver) => driver.key === category);

        if (!driver) {
            return;
        }

        setDriverTeam(driver);
        setPageTitle(t('catalog.title-part-1') + ' ' + driver.name + ' ' + t('catalog.title-part-2') + ' | TheRaceworks');
        setPageDesc(t('catalog.sub-title-part-1') + ' ' + driver.name + ' ' + t('catalog.meta-desc-part-2'));

        // drivers
        if (driver.team) {
            import(`../../assets/img/catalog-hero/${driver.team}.jpg`)
                .then(image => {
                    setHeroImage(image.default);
                })
                .catch (error => {
                    import(`../../assets/img/catalog-hero/${driver.team}.svg`)
                        .then(image => {
                            setHeroImage(image.default);
                        })
                });
        }
        
        // teams
        else {
            import(`../../assets/img/catalog-hero/${category}.jpg`)
                .then(image => {
                    setHeroImage(image.default);
                })
                .catch (error => {
        
                    import(`../../assets/img/catalog-hero/${category}.svg`)
                        .then(image => {
                            setHeroImage(image.default);

                            import(`../../assets/img/team-logo/${category}.svg`)
                                .then(image => {
                                setTeamLogo(image.default);
                            });
                        })
                });
        }
    }, [category])


    return (
        <>
        {driverTeam ? 
            <div className={`${Style.background} ${driverTeam.key} ${isMobile && Style.mobile} catalog-hero`} style={heroImage && {backgroundImage: `url(${heroImage})`}} >
                {pageTitle && pageDesc && category && 
                    <Helmet>
                        <title>{pageTitle.toString()}</title>
                        <meta name="description" content={pageDesc.toString()} />

                        <link rel="alternate" hreflang="en-gb" href={`https://uk.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="en-us" href={`https://www.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="en-au" href={`https://au.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="en-nz" href={`https://nz.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="nl-nl" href={`https://nl.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="en-nl" href={`https://en-nl.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="fr-fr" href={`https://fr.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="en-fr" href={`https://en-fr.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="de-de" href={`https://de.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="en-de" href={`https://en-de.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="es-es" href={`https://es.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="en-es" href={`https://en-es.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="pt-pt" href={`https://pt.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="en-pt" href={`https://en-pt.theraceworks.com/catalog/${category}`} />
                        <link rel="alternate" hreflang="x-default" href={`https://www.theraceworks.com/catalog/${category}`} />
                    </Helmet>
                }
                <Content>
                    <div className={Style.outerCont}>
                        <div className={Style.textCont}>
                            <h1>{t('catalog.title-part-1')} {driverTeam.name} {t('catalog.title-part-2')}</h1>
                            <p>{t('catalog.sub-title-part-1')} {driverTeam.name} {t('catalog.sub-title-part-2')}</p>
                        </div>
                        {teamLogo && 
                            <div className={Style.logoCont}>
                                <img src={teamLogo} alt={driverTeam.name} />
                            </div>
                        }
                    </div>
                </Content>
            </div>
        :
            <div className={`${Style.background} ${Style.noContent}`}>

            </div>
        }
        </>

    );
}