import React, {useState, useEffect} from "react";
import QuantitySelect from '../ui/QuantitySelect';
import DeleteButton from '../ui/DeleteButton';
import { useDispatch, useSelector } from "react-redux";
import Theme from '../../theme.module.css';
import Style from './CartSingleItem.module.css';
import { updateCart } from "../cart/cartSlice";
import { CurrencyFormat } from "../internationalisation/internationalisation";
import { useTranslation } from "react-i18next";


export default function CartItemSingle({cartItem}) {

    const {t, i18n} = useTranslation('common');
    const cartData = useSelector(state => state.cart);
    const dispatch = useDispatch();
    const {isMobile} = useSelector(state => state.mobile);
    const {taxRate} = useSelector(state => state.shippingRates);
    const {currentCurrency, pricesInclusiveTax} = useSelector(state => state.internationalisation);
    const [displayLineTotal, setDisplayLineTotal] = useState(cartItem.line_total);


    useEffect(() => {

        let newLineTotal = cartItem.line_total;

        if (pricesInclusiveTax && taxRate.rate && taxRate.rate > 0) {
            let lineTax = parseFloat(cartItem.line_total / 100 * parseFloat(taxRate.rate));
            newLineTotal = cartItem.line_total + lineTax;
        }

        setDisplayLineTotal(newLineTotal);
    }, [cartItem, taxRate])

    function handleQuantityChange(itemKey, newQuantity) {
        
        console.log('should handle qty');
        console.log(itemKey);
        console.log(newQuantity);

        let newLineItems = [];

        cartData.lineItems.forEach((lineItem, index) => {
            if (lineItem.key !== itemKey) {
                newLineItems.push(lineItem);
            }

            // update qty for this one
            else {
                console.log('updating qty for this one');
                console.log(lineItem);
                newLineItems.push({
                    ...lineItem,
                    quantity: newQuantity
                });
            }
        });

        let newCartData = {
            ...cartData,
            lineItems: newLineItems
        }

        dispatch(updateCart({cartData: newCartData}));
    }

    function handleDeleteItem(itemKey) {

        console.log('delete btn clicked');

        let newLineItems = [];

        cartData.lineItems.forEach((lineItem, index) => {
            if (lineItem.key !== itemKey) {
                newLineItems.push(lineItem);
            }
        });

        let newCartData = {
            ...cartData,
            lineItems: newLineItems
        }

        dispatch(updateCart({cartData: newCartData}));
    }

    return (
        <div className={`${Theme.flexRow} ${Style.cartSingleItem} ${isMobile && Style.mobile}`} itemKey={cartItem.key}>
            <div className={`${Theme.productImage} ${Style.cartItemImage}`} style={{backgroundImage: "url(" + cartItem.product_image + ")"}}>
            </div>

            {!isMobile && displayLineTotal ? (
                <>
                    <p>{cartItem.product_name}</p>
                    <div className={Style.qtySelectCont}>
                        <QuantitySelect initialValue={cartItem.quantity} onQuantityChange={handleQuantityChange} cartItemKey={cartItem.key} />
                    </div>
                    <p className={Style.amount}>{CurrencyFormat(displayLineTotal, currentCurrency)}</p>
                    <DeleteButton handleOnClick={() => handleDeleteItem(cartItem.key)} text={t('delete-button.remove-item')} />
                </>
            ) : (
                <div className={Style.cartItemInner}>
                    <p>{cartItem.product_name}</p>
                    <div className={Style.cartItemControlCont}>
                        <div className={Style.qtySelectCont}>
                            <QuantitySelect initialValue={cartItem.quantity} onQuantityChange={handleQuantityChange} cartItemKey={cartItem.key} />
                        </div>
                        <p className={Style.amount}>{CurrencyFormat(displayLineTotal, currentCurrency)}</p>
                        <DeleteButton handleOnClick={() => handleDeleteItem(cartItem.key)} text={t('delete-button.remove-item')} />
                    </div>
                </div>
            )}
        </div>

    );
}