import React, { useState } from "react";
import { useSelector } from "react-redux";
import MyAccountHeader from "../features/myAccount/MyAccountHeader";
import MyAccountSidebar from "../features/myAccount/MyAccountSidebar";
import MyAccountOrders from "../features/myAccount/MyAccountOrders";
import LoginForm from "../features/user/LoginForm";
import Content from "../layouts/Content";
import './MyAccount.css';


export default function MyAccount() {

    const [activeTab, setActiveTab] = useState(false);
    const {user, logged_in, error}  = useSelector(state => state.user);
    const {isMobile} = useSelector(state => state.mobile);

    return (
        <div>
            <Content>
                <MyAccountHeader />
                <div className={`${isMobile && 'mobile'} myAccountCont`}>
                    <div>
                        <MyAccountSidebar setActiveTab={setActiveTab} />
                    </div>
                    <div className="myAccountRightCol">

                        {user && logged_in ? (
                            activeTab && activeTab === 'my-orders' &&
                                <>
                                    <MyAccountOrders />
                                </>
                        ) : (
                            <LoginForm />
                        )}
                    </div>
                </div>
            </Content>
        </div>
    );
}