import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from "../../services/woocom";

export const getOrders = createAsyncThunk('orders/getOrders', async () => {
    const response = await client.get('/wc-orders');
    return response.data;
})

export const getOrder = createAsyncThunk('orders/getOrder', async ({orderID, clientSecret = null}) => {

    let response;
    console.log('client secret getting order: ' + clientSecret);
    console.log('order id getting order: ' + orderID);

    if (clientSecret) {
        response = await client.get(`/wc-orders?id=${orderID}&client_secret=${clientSecret}`);
    } else {
        response = await client.get(`/wc-orders?id=${orderID}`);
    }

    return response.data;
})

export const updateOrder = createAsyncThunk('orders/updateOrder', async (orderData) => {
    const response = await client.post('/wc-orders', orderData);
    return response.data;
})

export const createOrder = createAsyncThunk('orders/createOrder', async (orderData) => {
    const response = await client.post('/wc-orders', orderData);
    return response.data;
})


export const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        orders: [],
        order: {},
        status: 'idle'
    },
    reducers: {
    },
    extraReducers: builder => {
        builder

            // get orders
            .addCase(getOrders.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getOrders.fulfilled, (state, action) => {
                state.orders = action.payload;
                state.status = 'idle';
            })

            // get single order
            .addCase(getOrder.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getOrder.fulfilled, (state, action) => {
                state.order = action.payload;
                state.status = 'idle';
            })

            // update order
            .addCase(updateOrder.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(updateOrder.fulfilled, (state, action) => {
                state.order = action.payload;
                state.status = 'idle';
            })

            // add order
            .addCase(createOrder.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.order = action.payload;
                state.status = 'idle';
            })
    }
})

export default ordersSlice;