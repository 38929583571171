import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../services/woocom.js';


export const getRates = createAsyncThunk('shipping/getRates', async () => {
    const response = await client.get('/wc_shipping_and_tax');
    return response.data;
})

export const updateRates = createAsyncThunk('shipping/updateRates', async (data) => {
    const response = await client.post('/wc_shipping_and_tax', data);
    return response.data;
})

export const updateAvailableRates = createAsyncThunk('shipping/updateAvailableRates', async (availableRates) => {
    return availableRates;
})

export const shippingRatesSlice = createSlice({
    name: 'shippingRates',
    initialState: {
        loading: false,
        availCountries: [],
        shippingRates: [],
        availableRates: [],
        taxRate: []
    },
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(getRates.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRates.fulfilled, (state, action) => {
                state.shippingRates = action.payload.shippingRates;
                state.availCountries = action.payload.availCountries;
                if (action.payload.taxRate) {
                    Object.keys(action.payload.taxRate).forEach((key, i) => {
                        if (i === 0) {
                            state.taxRate = action.payload.taxRate[key];
                        }
                    });
                }
                state.loading = false;
            })
            .addCase(updateRates.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateRates.fulfilled, (state, action) => {
                state.shippingRates = action.payload.shippingRates;
                state.availCountries = action.payload.availCountries;
                if (action.payload.taxRate) {
                    Object.keys(action.payload.taxRate).forEach((key, i) => {
                        if (i === 0) {
                            state.taxRate = action.payload.taxRate[key];
                        }
                    });
                }
                state.loading = false;
            })
            .addCase(updateAvailableRates.fulfilled, (state, action) => {
                state.availableRates = action.payload;
            });
    }
})


export default shippingRatesSlice;