
/**
 * Shop price display
 * 
 * @param {float} amount
 * @param {float} taxAmount
 * @param {object} locale
 * @returns {string} formattedPrice
 */
export function ShopPrice(amount, taxAmount, locale) {

    // inclusive / exclusive of tax
    if (locale.prices_inclusive_tax) {
        amount = amount + taxAmount;
    }

    let priceString = CurrencyFormat(amount, locale);

    return priceString;
}

/**
 * Currency price formatter
 * 
 * @param {float} amount
 * @param {string} currency
 * @returns {string} formattedPrice
 */
export function CurrencyFormat(amount, currency) {

    let numberFormat;

    switch (currency) {
        case 'GBP':
            numberFormat = 'en-GB';
            break;
        case 'EUR':
            numberFormat = 'de-DE';
            break;
        case 'USD':
            numberFormat = 'en-US';
            break;
        default:
            numberFormat = 'en-GB';
    }

    let priceString = new Intl.NumberFormat(numberFormat, {style: 'currency', currency: currency}).format(amount);

    return priceString;
}

/**
 * Geo-locate client
 */
export function geoLocationCheck() {

}


/**
 * Locale redirect
 * 
 * @param {string} locale
 */
export function localeRedirect(locale) {

    // get path
    let path = window.location.pathname;
    path = path.split('?')[0];

    if (path.charAt(0) === '/') {
        path = path.substr(1);
    }

    // get current subdomain
    let host = window.location.host;
    let subdomain = host.split('.')[0];

    if (locale == 'UK') {
        window.location.href = 'https://www.theraceworks.com/' + path + '?set_locale=' + locale.toLowerCase();
    } else {
        window.location.href = 'https://' + locale + '.theraceworks.com/' + path + '?set_locale=' + locale.toLowerCase();
    }

}