import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Style from "./LoginForm.module.css";
import Theme from "../../theme.module.css";
import Collapsible from "../collapsible/Collapsible";
import { register, login, googleLogin, facebookLogin } from "./userSlice";
import FacebookLogin from '@greatsumini/react-facebook-login';
import { GoogleLogin } from '@react-oauth/google';


export default function Login({closePopUp}) {

    const {t, i18n} = useTranslation('common');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [loginValidationMsg, setLoginValidationMsg] = useState('');
    const [registerValidationMsg, setRegisterValidationMsg] = useState('');
    const dispatch = useDispatch();
    const {error, logged_in, user} = useSelector(state => state.user);
    const {localeCode, localeLoading} = useSelector(state => state.internationalisation);

    // Facebook App ID
    let facebookAppID;

    if (localeCode && !localeLoading) {
        if (localeCode == 'UK' || localeCode == 'US' || localeCode == 'AU' || localeCode == 'NZ') {
            facebookAppID = process.env.REACT_APP_FACEBOOK_APP_ID_COM;
        } else {
            facebookAppID = process.env.REACT_APP_FACEBOOK_APP_ID_EU;
        }
    }

    console.log('facebookAppID', facebookAppID);

    function handleLoginSubmit(event) {
        event.preventDefault();
        console.log('login submit');
        setLoginValidationMsg('');

        dispatch(login({email, password}));
    }

    function emailChange(event) {
        setEmail(event.target.value)
    }

    function passwordChange(event) {
        setPassword(event.target.value)
    }

    function handleRegisterSubmit(event) {
        event.preventDefault();
        console.log('register submit');
        setRegisterValidationMsg('');

        if (password !== passwordConfirm) {
            setRegisterValidationMsg(t('login.password-mismatch'));
            return;
        }

        dispatch(register({customer_data: {
                email: email,
                password: password
            }
        }));
    }

    function passwordConfirmChange(event) {
        setPasswordConfirm(event.target.value)
    }

    useEffect(() => {
        if (error) {
            setLoginValidationMsg(t('login.failed'));
        }
    }, [error]);

    useEffect(() => {
        if (logged_in) {
            setLoginValidationMsg(t('login.success'));

            setTimeout(() => {
                // close modal
                if (closePopUp) {
                    closePopUp();
                }
            }, 3000);
        }
    }, [logged_in, user]);
    
    return (
        <div className={Style.loginCont}>

            <h3>{t('login.title')}</h3>
            <p>{t('login.sub-title')}</p>

            <form onSubmit={handleLoginSubmit}>
                <input type="text" name="email" value={email} onChange={emailChange} placeholder={t('login.email')} />
                <input type="password" name="password" value={password} onChange={passwordChange} placeholder={t('login.password')}/>
                <input type="submit" className={Theme.btnDarkMedium} value={t('login.title')}/>
            </form> 

            <Collapsible title={t('login.click-register')} classNames={Style.emailLogin}>
                <form onSubmit={handleRegisterSubmit}>
                    <input type="text" name="email" value={email} onChange={emailChange} placeholder={t('login.email')} />
                    <input type="password" name="password" value={password} onChange={passwordChange} placeholder={t('login.password')}/>
                    <input type="password" name="password-confirm" value={passwordConfirm} onChange={passwordConfirmChange} placeholder={t('login.confirm-password')}/>
                    <input type="submit" className={Theme.btnDarkMedium} value={t('login.register')} />
                </form>
            </Collapsible>

            <div className={Style.socialLoginCont}>
                <GoogleLogin
                    onSuccess={credentialResponse => {
                        console.log(credentialResponse);
                        dispatch(googleLogin({googleIDToken: credentialResponse}));
                    }}
                
                    onError={() => {
                        console.log('Login Failed');
                        setLoginValidationMsg(t('login.social-failed'));
                    }}

                    width={350}
                />

                {facebookAppID && 
                    <FacebookLogin
                        appId={facebookAppID}
                        onSuccess={(response) => {
                            console.log('Login Success!', response);
                        }}
                        onFail={(error) => {
                            console.log('Login Failed!', error);
                            setLoginValidationMsg(t('login.social-failed'));
                        }}
                        onProfileSuccess={(response) => {
                            console.log('Get Profile Success!', response);
                            dispatch(facebookLogin({
                                facebookLogin: true,
                                data: response
                            }));
                        }}
                        className="facebook-login-button"
                        style={{
                            backgroundColor: '#4267b2',
                            color: '#fff',
                            fontSize: '16px',
                            padding: '8px 24px',
                            border: 'none',
                            borderRadius: '4px',
                            width: '100%'
                        }}
                    />
                }
            </div>


            {registerValidationMsg ? <span className={Style.validationMgs}>{registerValidationMsg}</span> : ''}
            {loginValidationMsg ? <span className={Style.validationMgs}>{loginValidationMsg}</span> : ''}
        </div>
    );
}