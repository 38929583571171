import React, { useEffect, useState } from "react";
import Collapsible from "../collapsible/Collapsible";
import { useDispatch, useSelector } from "react-redux";
import CustomCheckbox from "../customCheckbox/CustomCheckbox";
import { useTranslation } from "react-i18next";
import Style from "./CatalogFilters.module.css";


export default function CatalogFilters({category, selectedFilters, setSelectedFilters}) {

    const dispatch = useDispatch();
    const {filters} = useSelector(state => state.productCatalog);
    const [searchTerm, setSearchTerm] = useState('');
    const {t, i18n} = useTranslation('common');


    useEffect(() => {

        if (!category || filters.length < 1) {
            return; 
        }

        // get search term from query string
        const urlParams = new URLSearchParams(window.location.search);
        const theSearchTerm = urlParams.get('search')
        setSearchTerm(theSearchTerm);

        let newSelectedFilters = {
            pa_colour: [],
            types: [],
            drivers: [],
            teams: [],
            offers: [],
            searchTerm: theSearchTerm,
            orderby: 'newest'
        };

        Object.keys(filters).forEach((tax) => {

            // preset selected filters from category slug
            filters[tax].forEach((term) => {
                if (term.slug == category) {

                    let newTax = [term.slug];
                    newSelectedFilters[tax] = newTax;
                }
            });
        });

        // special offers
        if (category == 'sale') {
            newSelectedFilters.offers = ['on_sale'];
        }

        console.log(newSelectedFilters);
        setSelectedFilters(newSelectedFilters);

    }, [category, filters])
    

    // update selectedFilters when a filter is changed
    function handleFilterChange({term, tax, newChecked}) {

        let newSelectedFilters = {...selectedFilters};

        // add new term
        if (newChecked) {
            let newTax = [...newSelectedFilters[tax], term];
            newSelectedFilters[tax] = newTax;
        }

        // remove term
        else {
            let newTax = newSelectedFilters[tax].filter((item) => item !== term);
            newSelectedFilters[tax] = newTax;
        }

        setSelectedFilters(newSelectedFilters);
    }

    // update selectedFilters when orderby is changed
    function handleOrderbyChange(e) {

        let newSelectedFilters = {...selectedFilters};
        newSelectedFilters.orderby = e.target.value;
        console.log('orderby changed: ' + e.target.value);

        setSelectedFilters(newSelectedFilters);
    }


    // update selectedFilters when search term is removed
    function handleSearchTermRemove() {

        setSearchTerm('');

        let newSelectedFilters = {...selectedFilters};

        newSelectedFilters.searchTerm = '';

        setSelectedFilters(newSelectedFilters);
    }

    return (
        <>
        {filters && (
            <div>
                <Collapsible title={t('catalog.filter-by')} open={true}>
                    <ul>
                        {searchTerm && 
                            <div class="search-term-cont">
                                <div><span>{searchTerm}</span><button onClick={handleSearchTermRemove}>+</button></div>
                            </div>
                        }

                        <li>
                            <Collapsible title={t('catalog.offers')} open={false}>
                                <ul>
                                    <li>
                                        <CustomCheckbox label={t('catalog.on-sale')} term='on_sale' tax="offers" checked={(category == 'sale')} onChange={handleFilterChange} />
                                    </li>
                                </ul>
                            </Collapsible>
                        </li>

                        {filters.pa_colour && Array.isArray(filters.pa_colour) && (
                            <li>
                                <Collapsible title={t('catalog.colour')} open={false}>
                                    <ul>
                                        {filters.pa_colour.map((term) => (
                                            <li key={term.slug}>
                                                <CustomCheckbox label={term.name} term={term.slug} tax="pa_colour" checked={(category == term.slug)} onChange={handleFilterChange} />
                                            </li>
                                        ))}
                                    </ul>
                                </Collapsible>
                            </li>
                        )}

                        {filters.types && Array.isArray(filters.types) && (
                            <li>
                                <Collapsible title={t('catalog.type')} open={false}>
                                    <ul>
                                        {filters.types.map((term) => (
                                            <li key={term.slug}>
                                                <CustomCheckbox label={term.name} term={term.slug} tax="types" checked={(category == term.slug)} onChange={handleFilterChange} />
                                            </li>
                                        ))}
                                    </ul>
                                </Collapsible>
                            </li>
                        )}

                        {filters.drivers && Array.isArray(filters.drivers) && (
                            <li>
                                <Collapsible title={t('catalog.driver')} open={false}>
                                    <ul>
                                        {filters.drivers.map((term) => (
                                            <li key={term.slug}>
                                                <CustomCheckbox label={term.name} term={term.slug} tax="drivers" checked={(category == term.slug)} onChange={handleFilterChange} />
                                            </li>
                                        ))}
                                    </ul>
                                </Collapsible>
                            </li>
                        )}

                        {filters.teams && Array.isArray(filters.teams) && (
                            <li>
                                <Collapsible title={t('catalog.team')} open={false}>
                                    <ul>
                                        {filters.teams.map((term) => (
                                            <li key={term.slug}>
                                                <CustomCheckbox label={term.name} term={term.slug} tax="teams" checked={(category == term.slug)} onChange={handleFilterChange} />
                                            </li>
                                        ))}
                                    </ul>
                                </Collapsible>
                            </li>
                        )}

                    </ul>
                </Collapsible>

                <div className={Style.sortBy}>
                    <h3>{t('catalog.sort-by')}</h3>
                    <select onChange={handleOrderbyChange}>
                        <option value="newest">{t('catalog.sort-by-newest')}</option>
                        <option value="price-asc">{t('catalog.sort-by-cheapest')}</option>
                        <option value="price-desc">{t('catalog.sort-by-most-expensive')}</option>
                    </select>
                </div>
            </div>
        )}
        </>
    );
}   