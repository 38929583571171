import React from "react";
import Style from "./SizingInformation.module.css";
import { useTranslation } from 'react-i18next';


export default function SizingInformation() {

    const {t, i18n} = useTranslation('common');

    return (
        <div>
            <p>{t('sizing.desc')}</p>

            <table className={Style.table}>
                <thead>
                    <tr>
                    <th colspan="4">{t('sizing.mens')}</th>
                    </tr>
                    <tr>
                    <th>CM</th>
                    <th>CHEST</th>
                    <th>WAIST</th>
                    <th>HIPS</th>
                    <th>DEU</th>
                    <th>UK</th>
                    <th>FRA</th>
                    <th>ITA</th>
                    <th>SP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>XXS</td>
                    <td>89</td>
                    <td>77</td>
                    <td>92</td>
                    <td>XXS</td>
                    <td>38</td>
                    <td>38</td>
                    <td>38</td>
                    <td>38</td>
                    </tr>
                    <tr>
                    <td>XS</td>
                    <td>91</td>
                    <td>79</td>
                    <td>94</td>
                    <td>XS</td>
                    <td>40/42</td>
                    <td>40/42</td>
                    <td>40/42</td>
                    <td>40/42</td>
                    </tr>
                    <tr>
                    <td>S</td>
                    <td>93</td>
                    <td>81</td>
                    <td>97</td>
                    <td>S</td>
                    <td>44/46</td>
                    <td>44/46</td>
                    <td>44/46</td>
                    <td>44/46</td>
                    </tr>
                    <tr>
                    <td>M</td>
                    <td>97</td>
                    <td>85</td>
                    <td>99</td>
                    <td>M</td>
                    <td>48/50</td>
                    <td>48/50</td>
                    <td>48/50</td>
                    <td>48/50</td>
                    </tr>
                    <tr>
                    <td>L</td>
                    <td>102</td>
                    <td>92</td>
                    <td>103</td>
                    <td>L</td>
                    <td>52/54</td>
                    <td>52/54</td>
                    <td>52/54</td>
                    <td>52/54</td>
                    </tr>
                    <tr>
                    <td>XL</td>
                    <td>112</td>
                    <td>105</td>
                    <td>110</td>
                    <td>XL</td>
                    <td>56/58</td>
                    <td>56/58</td>
                    <td>56/58</td>
                    <td>56/58</td>
                    </tr>
                    <tr>
                    <td>XXL</td>
                    <td>120</td>
                    <td>114</td>
                    <td>117</td>
                    <td>XXL</td>
                    <td>60/62</td>
                    <td>60/62</td>
                    <td>60/62</td>
                    <td>60/62</td>
                    </tr>
                    <tr>
                    <td>XXXL</td>
                    <td>130</td>
                    <td>122</td>
                    <td>124</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>
                </tbody>
            </table>

            <table className={Style.table}>
                <thead>
                    <tr>
                    <th colspan="4">{t('sizing.womens')}</th>
                    </tr>
                    <tr>
                    <th>CM</th>
                    <th>CHEST</th>
                    <th>WAIST</th>
                    <th>HIPS</th>
                    <th>DEU</th>
                    <th>UK</th>
                    <th>FRA</th>
                    <th>ITA</th>
                    <th>SP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>XXS</td>
                    <td>78</td>
                    <td>62</td>
                    <td>90</td>
                    <td>XXS</td>
                    <td>32</td>
                    <td>6</td>
                    <td>34</td>
                    <td>36</td>
                    </tr>
                    <tr>
                    <td>XS</td>
                    <td>82</td>
                    <td>65</td>
                    <td>93</td>
                    <td>XS</td>
                    <td>34</td>
                    <td>8</td>
                    <td>36</td>
                    <td>38</td>
                    </tr>
                    <tr>
                    <td>S</td>
                    <td>86</td>
                    <td>68</td>
                    <td>96</td>
                    <td>S</td>
                    <td>36</td>
                    <td>10</td>
                    <td>38</td>
                    <td>40</td>
                    </tr>
                    <tr>
                    <td>M</td>
                    <td>91</td>
                    <td>74</td>
                    <td>101</td>
                    <td>M</td>
                    <td>38</td>
                    <td>12</td>
                    <td>40</td>
                    <td>42</td>
                    </tr>
                    <tr>
                    <td>L</td>
                    <td>96</td>
                    <td>80</td>
                    <td>106</td>
                    <td>L</td>
                    <td>40</td>
                    <td>14</td>
                    <td>42</td>
                    <td>44</td>
                    </tr>
                    <tr>
                    <td>XL</td>
                    <td>101</td>
                    <td>86</td>
                    <td>112</td>
                    <td>XL</td>
                    <td>42</td>
                    <td>16</td>
                    <td>44</td>
                    <td>46</td>
                    </tr>
                    <tr>
                    <td>XXL</td>
                    <td>107</td>
                    <td>114</td>
                    <td>118</td>
                    <td>XXL</td>
                    <td>44</td>
                    <td>18</td>
                    <td>46</td>
                    <td>48</td>
                    </tr>
                </tbody>
            </table>

            <table className={Style.table}>
                <thead>
                    <tr>
                    <th colspan="4">{t('sizing.kids')}</th>
                    </tr>
                    <tr>
                    <th>DEU</th>
                    <th>UK</th>
                    <th>FRA</th>
                    <th>ITA</th>
                    <th>SP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>XXS</td>
                    <td>104</td>
                    <td>3/4</td>
                    <td>4</td>
                    <td>104</td>
                    <td>4</td>
                    </tr>
                    <tr>
                    <td>XS</td>
                    <td>116</td>
                    <td>5/6</td>
                    <td>6</td>
                    <td>116</td>
                    <td>6</td>
                    </tr>
                    <tr>
                    <td>S</td>
                    <td>128</td>
                    <td>24/26</td>
                    <td>8</td>
                    <td>128</td>
                    <td>8</td>
                    </tr>
                    <tr>
                    <td>M</td>
                    <td>140</td>
                    <td>26/28</td>
                    <td>10</td>
                    <td>140</td>
                    <td>10</td>
                    </tr>
                    <tr>
                    <td>L</td>
                    <td>152</td>
                    <td>30/32</td>
                    <td>12</td>
                    <td>152</td>
                    <td>12</td>
                    </tr>
                    <tr>
                    <td>XL</td>
                    <td>164</td>
                    <td>32/34</td>
                    <td>14</td>
                    <td>164</td>
                    <td>14</td>
                    </tr>
                    <tr>
                    <td>XXL</td>
                    <td>176</td>
                    <td>34/36</td>
                    <td>16</td>
                    <td>176</td>
                    <td>16</td>
                    </tr>
                </tbody>
            </table>

        </div>
    );
}