import React from "react";
import { useSelector } from "react-redux";

export default function DeleteButton({handleOnClick, text}) {

    const {isMobile} = useSelector(state => state.mobile);
    
    return (
        <>
            {!isMobile ? (
                <button onClick={handleOnClick}>{text}</button>
            ) : ( 
                <button onClick={handleOnClick} style={{transform: "rotate(45deg)", fontSize: "20px"}}>+</button>
            )}
        </>
    );
}