import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Style from "./rightSlideIn.css";
import Theme from '../../theme.module.css';
import { CSSTransition } from "react-transition-group";

export default function RightSlideIn({children}) {

    const [rendered, setRendered] = useState(false);
    const { isMobile } = useSelector(state => state.mobile);

    useEffect(() => {
        setTimeout(function() {
            setRendered(true);
        }, 100);
    }, []);

    return (
        <CSSTransition 
            in={rendered}
            classNames={!isMobile && "slide"} 
            timeout={1000}
        >
            <div className={`${isMobile ? 'mobileBottomCont' : 'rightSlideIn'}`} style={{ background: `var(--color-grey-3)` }}>
                {children}
            </div>
        </CSSTransition>
    );
}