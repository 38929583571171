import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import store from './store/configureStore';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import { GoogleOAuthProvider } from '@react-oauth/google';
import common_en from "./data/translations/en/common.json";
import common_fr from "./data/translations/fr/fr.json";
import common_nl from "./data/translations/nl/nl.json";
import common_it from "./data/translations/it/it.json";
import common_de from "./data/translations/de/de.json";
import common_es from "./data/translations/es/es.json";
import common_pt from "./data/translations/pt/pt.json";


const root = ReactDOM.createRoot(document.getElementById('root'));
const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: common_en
    },
    fr: {
      common: common_fr
    },
    nl: {
      common: common_nl
    },
    it: {
      common: common_it
    },
    de: {
      common: common_de
    },
    es: {
      common: common_es
    },
    pt: {
      common: common_pt
    }
  }
});

root.render(
  <>
  {/* <React.StrictMode> */}
    <Provider store={store}>  
      <I18nextProvider i18n={i18next}>
        <GoogleOAuthProvider clientId={googleClientID}>
          <App />
        </GoogleOAuthProvider>
      </I18nextProvider>
    </Provider>
  {/* </React.StrictMode> */}
  </>
);