import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Style from "./MyAccountSidebar.module.css";
import { useTranslation } from "react-i18next";


export default function MyAccountSidebar0({ setActiveTab }) {

    const {t, i18n} = useTranslation('common');
    const {isMobile} = useSelector(state => state.mobile);

    useEffect(() => {
        setActiveTab('my-orders');
    }, [])

    return (
        <div className={`${Style.outer} ${isMobile && Style.mobile}`}>
            <div className={Style.inner}>
                <ul>
                    <li onClick={setActiveTab('my-orders')}>{t('my-account.my-orders')}</li>
                </ul>
            </div>
        </div>
    );
}