import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function ShippingAddress({order}) {

    const {shippingAddress} = order;
    const {t, i18n} = useTranslation('common');

    return (
        <div>
            {shippingAddress && 
                <>
                    <table>
                        <tr>
                            <td>{shippingAddress.shippingFirstName} {shippingAddress.shippingLastName}</td>
                        </tr>
                        {shippingAddress.shippingCompany &&
                            <tr>
                                <td>{shippingAddress.shippingFirstName}</td>
                            </tr>
                        }
                        <tr>
                            <td>{shippingAddress.shippingAddress1}</td>
                        </tr>
                        {shippingAddress.shippingAddress2 &&
                            <tr>
                                <td>{shippingAddress.shippingAddress2}</td>
                            </tr>
                        }
                        <tr>
                            <td>{shippingAddress.shippingCity}{shippingAddress.shippingPostcode &&
                                <span>, {shippingAddress.shippingPostcode}</span>
                            }</td>
                        </tr>
                        {shippingAddress.shippingState &&
                            <tr>
                                <td>{shippingAddress.shippingState}</td>
                            </tr>
                        }
                        <tr>
                            <td>{shippingAddress.shippingCountry}</td>
                        </tr>
                    </table>
                </>
            }
        </div>
    );
}