import React from "react";
import "./CatalogSidebar.css";


export default function CatalogSidebar({children}) {

    return (
        <div className="desktop catalogSidebar">
            {children}
        </div>
    );
}