import React, { useEffect } from "react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { languages } from "../../data/languages";
import client from '../../services/woocom.js';

export const setSessionLocale = createAsyncThunk('internationalisation/setLocale', async (language = null) => { 
    const response = await client.get('/locale', {params: {language: language}});
    return response.data;
})

export const setLanguage = createAsyncThunk('internationalisation/setLanguage', async (language) => {
    return language;
})

export const internationalisationSlice = createSlice({
    name: 'internationalisation',
    initialState: {
        localeLoading: true,
        localeCode: 'UK',
        currentLanguage: 'en',
        currentCurrency: 'GBP',
        pricesInclusiveTax: false,
    },
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(setSessionLocale.pending, (state) => {
                state.localeLoading = true;
            })
            .addCase(setSessionLocale.fulfilled, (state, action) => {
                console.log('Set locale: ', action.payload);
                state.localeLoading = false;
                state.localeCode = action.payload.locale.code;
                state.currentLanguage = action.payload.locale.language;
                state.currentCurrency = action.payload.locale.currency;

                if (action.payload.locale.prices_inclusive_tax) {
                    state.pricesInclusiveTax = true;
                } else {
                    state.pricesInclusiveTax = false;
                }

                localStorage.setItem('GSHW_LANGUAGE', action.payload.locale.language);
            })
            .addCase(setLanguage.pending, (state) => {
                state.localeLoading = true;
            })
            .addCase(setLanguage.fulfilled, (state, action) => {
                state.localeLoading = false;
                state.currentLanguage = action.payload;
                localStorage.setItem('GSHW_LANGUAGE', action.payload);
            })
    }
});

export default internationalisationSlice
