import React, { useState, useEffect } from "react";
import HeaderCart from "../layouts/header/HeaderCart";
import Content from "../layouts/Content";
import RightSlideIn from "../layouts/sidebar/RightSlideIn";
import OrderSubTotals from "../features/checkout/OrderSubTotals";
import OrderTotal from "../features/checkout/OrderTotal";
import { Link } from "react-router-dom";
import Theme from "../theme.module.css";
import "./Checkout.css";
import CheckoutForm from "../features/checkout/CheckoutForm";
import OrderItems from "../features/checkout/OrderItems";
import StripePaymentWrapper from "../features/checkout/StripePaymentWrapper";
import ShippingRates from "../features/shippingRates/ShippingRates";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


export default function Checkout() {

    const {isMobile} = useSelector(state => state.mobile);
    const {t, i18n} = useTranslation('common');
    const {lineItems} = useSelector(state => state.cart);
    const [visibleFix, setVisibleFix] = useState(false);
    const [step, setStep] = useState(1);

    useEffect(() => {
        setTimeout(function() {
            setVisibleFix(true);
        }, 200);
    }, []);

    return (
        <>
            {!isMobile &&
                <div className={`${visibleFix && 'slide-enter-done'} checkoutCont`}>
                    <div className="inner">
                        <div className="content">
                            <div className="fade-1">
                                <HeaderCart />
                                <h1>{t('cart.checkout')}</h1>
                                {lineItems && lineItems.length > 0 ? (
                                    <StripePaymentWrapper>
                                        <CheckoutForm step={step} setStep={setStep} />
                                    </StripePaymentWrapper>
                                ) : (
                                    <p>{t('cart.no-items')}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    
                    <RightSlideIn>
                        <div className="fade-2">
                            <h3>Summary</h3>
                            <OrderItems />
                            <ShippingRates />
                            <OrderSubTotals />
                            { /*<PromoCode /> */}
                            <div className="bottomCont">
                                <OrderTotal />
                            </div>
                        </div>
                    </RightSlideIn>
                </div>
            }
            
            {isMobile &&
                <div className="mobileCheckoutCont">
                    <div className="inner">
                        <div className="fade-1">
                            <div className="cartCheckoutHeaderCont">
                                <HeaderCart />
                                <h1>{t('cart.checkout')}</h1>
                            </div>

                            {lineItems && lineItems.length > 0 ? (
                                <StripePaymentWrapper>
                                    <CheckoutForm step={step} setStep={setStep} />
                                </StripePaymentWrapper>
                            ) : (
                                <p>{t('cart.empty')}</p>
                            )}
                        </div>
                    </div>
                </div>
            }
        </>
    );
}