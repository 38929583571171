import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { CurrencyFormat } from "../internationalisation/internationalisation";
import { useTranslation } from "react-i18next";
import "./SingleCatalogProduct.css";


export default function SingleCatalogProduct({product}) {

    const {isMobile} = useSelector(state => state.mobile);
    const {currentCurrency, pricesInclusiveTax} = useSelector(state => state.internationalisation);
    const {t, i18n} = useTranslation('common');

    function handleAddToCart(e) {
        e.preventDefault();
    }

    return (
        <Link to={`/product/${product.slug}`} className="singleCatProd">
            <div className={`${isMobile && 'mobile'} singleCatProdInner`}>
                <div className="singleCatProdImgOuter">
                    {product.on_sale && (
                        <div className="saleBanner">
                            <p>{t('catalog.on-sale')}</p>
                        </div>
                    )}
                    <div style={{backgroundImage: "url(" + product.imageUrl + ")"}} className="singleCatProdImgInner">

                    </div>
                </div>
                <img  className="singleCatProdImg" />
                <div className="singleCatProdInfo">
                    {!isMobile &&
                        <img src={require('../../assets/img/icon_cart.svg').default} className="singleProdCartIcon" alt="cart" onClick={handleAddToCart} />
                    }
                    <div>
                        <p className="productName">{product.name}</p>
                        {product.minPrice ? (
                            product.minPrice === product.maxPrice ? (
                                <>
                                    {pricesInclusiveTax && product.min_tax_amount > 0 ? (
                                        <p className="productPrice">{CurrencyFormat(parseFloat(product.minPrice) + parseFloat(product.min_tax_amount), currentCurrency)}</p>
                                    ) : (
                                        <p className="productPrice">{CurrencyFormat(product.minPrice, currentCurrency)}</p>
                                    )}
                                </>
                            ) : (
                                <>
                                    {pricesInclusiveTax && product.min_tax_amount && product.max_tax_amount > 0 ? (
                                        <p className="productPrice">{CurrencyFormat(parseFloat(product.minPrice) + parseFloat(product.min_tax_amount), currentCurrency)} - {CurrencyFormat(parseFloat(product.maxPrice) + parseFloat(product.max_tax_amount), currentCurrency)}</p>
                                    ) : (
                                        <p className="productPrice">{CurrencyFormat(product.minPrice, currentCurrency)} - {CurrencyFormat(product.maxPrice, currentCurrency)}</p>
                                    )}
                                </>
                            )
                        ) : (
                            <>
                                {pricesInclusiveTax && product.tax_amount > 0 ? (
                                    <p className="productPrice">{CurrencyFormat(parseFloat(product.price) + parseFloat(product.tax_amount), currentCurrency)}</p>
                                ) : (
                                    <p className="productPrice">{CurrencyFormat(product.price, currentCurrency)}</p>
                                )}
                            </>
                        )}
                    </div>
                </div>

            </div>
        </Link>
    );
}