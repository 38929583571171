import React, {useState} from "react";
import Theme from "../../theme.module.css";
import Style from "./ContactForm.module.css";
import client from "../../services/woocom";
import { useTranslation } from "react-i18next";


export default function ContactForm() {

    const contactServiceUrl = '/contact-form';
    const {t, i18n} = useTranslation('common');
    const [validationMsg, setValidationMsg] = useState('');
    const [fields, setFields] = useState({
        name: '',
        email: '',
        message: ''
    });

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        setFields({...fields, [name]: value});
    }

    async function handleSubmit(event) {
        event.preventDefault();

        console.log(fields);

        try {
            const response = await client.post(contactServiceUrl, fields);
            console.log(response.data);
      
            // Reset the form
            setFields({
              name: '',
              email: '',
              message: ''
            });

            // show success message
            setValidationMsg(t('contact.successful-sending'));
            

        } catch (error) {
            // Handle any errors
            console.error(error);
            setValidationMsg(t('contact.problem-sending') + '<a href="mailto:info@theraceworks.com">info@theraceworks.com</a>');
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit} className={Style.contactForm}>
                <label>
                    Name
                    <input type="text" name="name" onChange={handleChange} value={fields.name} />
                </label>
                <label>
                    Email
                    <input type="email" name="email" onChange={handleChange} value={fields.email} />
                </label>
                <label>
                    Message
                    <textarea name="message" onChange={handleChange} value={fields.message}></textarea>
                </label>
                <input type="submit" className={Theme.btnDarkMedium} style={{marginTop: "20px", display: "inline-block"}} value={t('contact.send')}/>

                {validationMsg && 
                    <p className="contactFormValidationMsg">{validationMsg}</p>
                }
            </form>
        </>
    );
}