import React, { useState, useEffect } from "react";
import "./Cart.css";
import Theme from "../theme.module.css";
import Content from '../layouts/Content';
import CartItems from "../features/cart/CartItems";
import HeaderCart from "../layouts/header/HeaderCart";
import RightSlideIn from "../layouts/sidebar/RightSlideIn";
import OrderSubTotals from "../features/checkout/OrderSubTotals";
import OrderTotal from "../features/checkout/OrderTotal";
import ShippingRates from "../features/shippingRates/ShippingRates";
import GoBack from "../features/goBack/GoBack";
import PromoCode from "../features/promoCode/PromoCode";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


export default function Cart() {

    const { isMobile } = useSelector(state => state.mobile);
    const {t, i18n} = useTranslation('common'); 
    const [visibleFix, setVisibleFix] = useState(false);

    useEffect(() => {
        setTimeout(function() {
            setVisibleFix(true);
        }, 200);
    }, []);

    return (
        <>
            {!isMobile && 
                <div className={`${isMobile && 'mobileCartCont'} ${visibleFix && 'slide-enter-done'} cartCont`}>
                    <div className="inner">
                        <div className="content">                
                            <HeaderCart />
                            <div className="fade-1">
                                <h1>{t('cart.title')}</h1>
                                <CartItems />
                                <GoBack text={t('cart.go-back')}/>
                            </div>
                        </div>
                    </div>
                    
                    <RightSlideIn>
                        <div className="fade-2">
                            <h3>{t('cart.summary')}</h3>
                            <ShippingRates />
                            <OrderSubTotals />
                            <PromoCode />
                            <div className="bottomCont">
                                <OrderTotal />
                                <Link to="/checkout" className={Theme.btnDarkBig}>{t('cart.buy-now')}</Link>
                            </div>
                        </div>
                    </RightSlideIn>
                </div>
            }

            {isMobile &&
                <div className="mobileCartContOuter">
                    <div className={`${isMobile && 'mobileCartCont'} cartCont`}>
                        <div className="content fade-1">   
                            <div className="cartCheckoutHeaderCont">   
                                <HeaderCart />
                                <h1>{t('cart.title')}</h1>
                            </div>
                            
                            <CartItems />
                        </div>
                    </div>

                    <RightSlideIn>
                        <div className="fade-2">
                            <img src={require('../assets/img/mobile_handle.svg').default} alt="" />
                            <h3>{t('cart.summary')}</h3>
                            <ShippingRates />
                            <OrderSubTotals />
                            <PromoCode />
                            <div className="bottomCont">
                                <OrderTotal />
                                <Link to="/checkout" className={Theme.btnDarkBig}>{t('cart.buy-now')}</Link>
                            </div>
                        </div>
                    </RightSlideIn>
                </div>
            }
        </>
    );
}