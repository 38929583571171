import React from "react";
import { useTranslation } from 'react-i18next';
import Content from "../layouts/Content";
import Style from "./LegalPage.module.css";
import { useSelector } from "react-redux";


export default function Returns() {

    const {t, i18n} = useTranslation('common');
    const {localeCode} = useSelector(state => state.internationalisation);
    const dotComLocales = ['US', 'UK', 'NZ', 'AU'];
    
    let translatedHTML;

    // .com sites
    if (dotComLocales.includes(localeCode)) {
        translatedHTML = t('legal.returns-dot-com-1');
        translatedHTML += t('legal.returns-dot-com-2');
        translatedHTML += t('legal.returns-dot-com-3');
        translatedHTML += t('legal.returns-dot-com-4');
    }

    // EU sites
    else {
        translatedHTML = t('legal.returns-1');
        translatedHTML += t('legal.returns-2');
        translatedHTML += t('legal.returns-3');
        translatedHTML += t('legal.returns-4');
    }

    translatedHTML = {__html:translatedHTML}

    return (
        <div className={Style.legalPageCont}>
            <Content>
                <h1>{t('legal.returns-title')}</h1>
                <div dangerouslySetInnerHTML={translatedHTML}>

                </div>
            </Content>
        </div>
    );
}