import React, {useState} from "react";
import client from '../../services/woocom.js';
import Style from './Subscribe.module.css';
import Theme from '../../theme.module.css';
import Content from '../../layouts/Content';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';


export default function Subscribe() {

    const [email, setEmail] = useState('');
    const {t, i18n} = useTranslation('common');
    const [message, setMessage] = useState('');
    const {isMobile} = useSelector(state => state.mobile);
    const location = useLocation();

    const subscribe = async (e) => {

        e.preventDefault();

        // validate 
        if (!email) {
            setMessage('Please enter a valid email address');
            return;
        }

        // subscribe
        setMessage('Subscribing...');

        const response = await client.post('/mailchimp/subscribe', {email: email});
        console.log('Subscribed', response);

        setMessage('Thank you for subscribing!');
    }

    return (
        <>
        {location.pathname !== '/checkout' &&
            <div className={`${Style.subscribeCont} ${isMobile && Style.mobile}`}>
                <Content>
                    <div className={`${Theme.flexRow} ${Style.subscribeInnerCont}`}>
                        <h3>{t('sign-up.sign-up')}</h3>
                        <h4>{t('sign-up.sign-up-msg')}</h4>
                        <div>
                            <form className={Theme.flexRow}>
                                <input type="email" placeholder={t('sign-up.placeholder')} value={email} onChange={(event) => {setEmail(event.target.value)}} />
                                <button onClick={subscribe} className={Style.subscribeBtn}>{t('sign-up.subscribe')}</button>
                            </form>
                            <span className={Style.message}>{message}</span>
                        </div>
                    </div>
                </Content>
            </div>
        }
        </>
    )
}