import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProductCatalogList from '../features/productCatalog/ProductCatalogList';
import Theme from '../theme.module.css';
import CatalogHero from '../features/productCatalog/CatalogHero';
import Content from '../layouts/Content';
import CatalogSidebar from '../layouts/sidebar/CatalogSidebar';
import CatalogFilters from '../features/productCatalog/CatalogFilters';
import CatalogSidebarMobile from '../layouts/sidebar/CatalogSidebarMobile';
import CatalogMobileButtons from '../features/productCatalog/CatalogMobileButtons';


export default function ProductCatalog() {

    const [selectedFilters, setSelectedFilters] = useState(null);
    const [filterMenuActive, setFilterMenuActive] = useState(false);
    const {isMobile} = useSelector(state => state.mobile);

    let params = useParams();
    let category;

    if (params.category) {
        category = params.category;
    } else {
        category = 'all';
    }

    useEffect(() => {
        console.log('selected filters: ');
        console.log(selectedFilters);
    }, [selectedFilters]);

    

    return (
        <>
        {category && (
            <section>
                <CatalogHero category={category} />
                {isMobile &&
                    <CatalogMobileButtons 
                        setFilterMenuActive={setFilterMenuActive}
                        selectedFilters={selectedFilters} 
                        setSelectedFilters={setSelectedFilters}
                    />
                }
                <section>
                    <Content>
                        <div className={Theme.flexRow} style={{width: "100%"}}>
                            {isMobile ? (
                                <CatalogSidebarMobile 
                                    filterMenuActive={filterMenuActive} 
                                    setFilterMenuActive={setFilterMenuActive} 
                                    selectedFilters={selectedFilters}
                                    setSelectedFilters={setSelectedFilters}
                                    category={category}
                                />
                            ) : (
                                <CatalogSidebar>
                                    {/* <Breadcrumbs /> */}
                                    <CatalogFilters 
                                        category={category} 
                                        selectedFilters={selectedFilters} 
                                        setSelectedFilters={setSelectedFilters}
                                    />
                                </CatalogSidebar>
                            )}

                            <ProductCatalogList selectedFilters={selectedFilters} />
                        </div>
                    </Content>
                </section>
            </section>
        )}
        </>
    )
}