import React from "react";
import { useTranslation } from "react-i18next";


export default function DeliveryInformation() {

    const {t, i18n} = useTranslation('common');

    return (
        <div>
            <h4>{t('product.dispatch-time')}</h4>
            <p>{t('product.dispatch-time-info')}</p>

            <h4>{t('product.tracking')}</h4>
            <p>{t('product.tracking-info')}</p>
        </div>
    );
}