import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from "../../services/woocom";
import { setAuthToken } from '../../services/auth';

export const login = createAsyncThunk('user/login', async ({email, password}) => {
    const response = await client.post('/wc-customers', {email, password});
    return response.data;
})

export const googleLogin = createAsyncThunk('user/googleLogin', async ({googleIDToken}) => {
    const response = await client.post('/wc-customers', {googleIDToken});
    return response.data;
})

export const facebookLogin = createAsyncThunk('user/facebookLogin', async (data) => {
    const response = await client.post('/wc-customers', data);
    return response.data;
})

export const register = createAsyncThunk('user/register', async ({customer_data}) => {
    const response = await client.post('/wc-customers', {customer_data});
    return response.data;
})

export const getUserData = createAsyncThunk('user/getUserData', async () => {
    const response = await client.get(`/wc-customers`);
    return response.data;
})


export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {},
        logged_in: false,
        error: '',
        status: 'idle'
    },
    reducers: {
    },
    extraReducers: builder => {
        builder

            // Login
            .addCase(login.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(login.fulfilled, (state, action) => {
                state.status    = 'idle';

                if (action.payload.status == 'success') {
                    state.logged_in = true;
                    state.user      = action.payload.user;
                    setAuthToken(action.payload.user.data.authToken);
                }
                else {
                    state.logged_in = false;
                    state.user      = {};
                    state.error     = action.payload.message;
                }

                console.log(action.payload);
            })

            // Google login
            .addCase(googleLogin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(googleLogin.fulfilled, (state, action) => {
                state.status    = 'idle';

                if (action.payload.status == 'success' && action.payload.user && action.payload.user.data.authToken) {
                    state.logged_in = true;
                    state.user      = action.payload.user;
                    setAuthToken(action.payload.user.data.authToken);
                }
                else {
                    state.logged_in = false;
                    state.user      = {};
                    state.error     = action.payload.message;
                }

                console.log(action.payload);
            })

            // Facebook login
            .addCase(facebookLogin.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(facebookLogin.fulfilled, (state, action) => {
                state.status    = 'idle';

                if (action.payload.status == 'success') {
                    state.logged_in = true;
                    state.user      = action.payload.user;
                    setAuthToken(action.payload.user.data.authToken);
                }
                else {
                    state.logged_in = false;
                    state.user      = {};
                    state.error     = action.payload.message;
                }

                console.log(action.payload);
            })

            // Register
            .addCase(register.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(register.fulfilled, (state, action) => {
                state.status    = 'idle';

                if (action.payload.status == 'success') {
                    state.logged_in = true;
                    state.user      = action.payload.user;
                    setAuthToken(action.payload.user.data.authToken);
                }
                else {
                    state.logged_in = false;
                    state.user      = {};
                    state.error     = action.payload.message;
                }

                console.log(action.payload);
            })

            // Get user data for logged in user
            .addCase(getUserData.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getUserData.fulfilled, (state, action) => {

                if (action.payload.status == 'success') {
                    state.logged_in = true;
                    state.user      = action.payload.user;
                }
                
                state.status    = 'idle';
            })
    }
})

export default userSlice;