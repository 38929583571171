import React, {useEffect, useState, useRef} from "react";
import "./Collapsible.css";


export default function CollapsibleGroup({children, title, open, setter, groupKey, classNames}) {

    const [contentHeight, setContentHeight] = useState("0px");
    const collapsibleContentInner = useRef(null);

    classNames += " collapsible";

    function handleCollapsibleToggle() {

        if (!open) {
            setter(groupKey);
        }
    }

    const handleSetContentHeight = () => {

        if (!collapsibleContentInner.current) {
            return;
        }

        const content = collapsibleContentInner.current;
        const newContentHeight = content.scrollHeight + "px";

        if (open) {
            setContentHeight(newContentHeight);
        }
        else {
            setContentHeight("0px");
        }
        
    }

    const preSetContentHeight = () => {

        if (!collapsibleContentInner.current) {
            return;
        }

        const content = collapsibleContentInner.current;
        const newContentHeight = content.scrollHeight + "px";

        setContentHeight(newContentHeight);
    }

    const unsetMaxHeight = () => {
        setContentHeight("none");
    }

    // handle toggle
    useEffect(() => {

        // closing
        if (!open) {
            preSetContentHeight();
        }

        // opening
        if (open) {
            handleSetContentHeight();
            setTimeout(() => {
                unsetMaxHeight();
            }, (300));    
        }

    }, [open]);

    // handle content height change
    useEffect(() => {

        if (!open) {
            setTimeout(() => {
                handleSetContentHeight();
            }, (0));    
        }
        
    }, [contentHeight]);

    useEffect(() => {
        handleSetContentHeight();
    }, []);


    return (
        <div className={classNames}>
                <div className={`collapsible-header ${open ? "open" : ""}`} onClick={handleCollapsibleToggle}>
                <p>{title}</p>
                <span>{open ? "-" : "+"}</span>
            </div>
            <div className={`collapsible-content ${open ? "collapsible-content-open" : ""}`} style={{ maxHeight: contentHeight }}>
                <div className="collapsible-content-inner" ref={collapsibleContentInner}>
                    {children}
                </div>
            </div>
        </div>
    );
}