import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Style from "./GoBack.module.css";


export default function GoBack({text}) {

    const navigate = useNavigate();

    function handleGoBack() {
        navigate(-1);
    }

    return (
        <a onClick={handleGoBack} className={Style.goBack}>
            <img src={require('../../assets/img/arrow_right_2.svg').default} alt="back" className={Style.backArrow} />
            {text}
        </a>
    )
}