import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CustomCheckbox from "../../features/customCheckbox/CustomCheckbox";
import Theme from "../../theme.module.css";
import Style from "./CatalogSidebarMobile.module.css";


export default function CatalogSidebarMobile({filterMenuActive, setFilterMenuActive, selectedFilters, setSelectedFilters, category}) {

    const [subMenuActive, setSubMenuActive] = React.useState('parent');
    const [searchTerm, setSearchTerm] = useState('');
    const {filters} = useSelector(state => state.productCatalog);
    const {t, i18n} = useTranslation('common');

    function handleClickOffFilters(e) {
        if (e.target == e.currentTarget) {
            console.log('clicked off menu');
            setFilterMenuActive(false);
            setSubMenuActive('parent');
        }
    }

    useEffect(() => {

        if (!category || filters.length < 1) {
            return; 
        }

        console.log('catalog changed to: ' + category);

        // get search term from query string
        const urlParams = new URLSearchParams(window.location.search);
        const theSearchTerm = urlParams.get('search')
        setSearchTerm(theSearchTerm);

        let newSelectedFilters = {
            pa_colour: [],
            types: [],
            drivers: [],
            teams: [],
            offers: [],
            searchTerm: theSearchTerm
        };

        Object.keys(filters).forEach((tax) => {

            // preset selected filters from category slug
            filters[tax].forEach((term) => {
                if (term.slug == category) {

                    let newTax = [term.slug];
                    newSelectedFilters[tax] = newTax;
                }
            });
        });

        // special offers
        if (category == 'sale') {
            newSelectedFilters.offers = ['on_sale'];
        }

        console.log('setting selected filters first render: ');
        console.log(newSelectedFilters);
        setSelectedFilters(newSelectedFilters);

    }, [category, filters])

    // update selectedFilters when a filter is changed
    function handleFilterChange({term, tax, newChecked}) {

        let newSelectedFilters = {...selectedFilters};

        // add new term
        if (newChecked) {
            let newTax = [...newSelectedFilters[tax], term];
            newSelectedFilters[tax] = newTax;
        }

        // remove term
        else {
            let newTax = newSelectedFilters[tax].filter((item) => item !== term);
            newSelectedFilters[tax] = newTax;
        }

        setSelectedFilters(newSelectedFilters);
    }

    // update selectedFilters when search term is removed
    function handleSearchTermRemove() {

        setSearchTerm('');

        let newSelectedFilters = {...selectedFilters};

        newSelectedFilters.searchTerm = '';

        setSelectedFilters(newSelectedFilters);
    }

    return (
        <>
            {filters && selectedFilters &&
                <CSSTransition
                    in={filterMenuActive}
                    timeout={500}
                    classNames={{
                        enter: "mobileFiltersEnter",
                        enterActive: "mobileFiltersEnterActive",
                        enterDone: "mobileFiltersEnterDone",
                        exitActive: "mobileFiltersExitActive",
                        exitDone: "mobileFiltersExitDone",
                    }}
                    unmountOnExit
                >
                    <div className={Style.menuBack} onClick={(e) => handleClickOffFilters(e)}>
                        <img src={require('../../assets/img/cross.svg').default} alt="close" onClick={(e) => handleClickOffFilters(e)} />

                        <div className={`${Style.catalogSidebar} catalogSidebarMobile`}>
                            <div className={`${Style.mobileMenuInner} ${subMenuActive !== '' && Style.subMenuActive}`}>
                            
                                <CSSTransition
                                    in={subMenuActive == 'parent'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div className={Style.parentMenu}>
                                        <div className={Style.subMenuTitleCont}>
                                            <h3>{t('catalog.filter-by')}</h3>
                                        </div>

                                        {searchTerm && 
                                            <div class={`${Style.mobSearchTermCont} search-term-cont`}>
                                                <div><span>{searchTerm}</span><button onClick={handleSearchTermRemove}>+</button></div>
                                            </div>
                                        }

                                        <ul className={Style.mainParentList}>
                                            <li onClick={() => {setSubMenuActive('teams')}}>{t('catalog.team')}</li>
                                            <li onClick={() => {setSubMenuActive('drivers')}}>{t('catalog.driver')}</li>
                                            <li onClick={() => {setSubMenuActive('items')}}>{t('catalog.item')}</li>
                                            <li onClick={() => {setSubMenuActive('colours')}}>{t('catalog.colour')}</li>
                                            <li onClick={() => {setSubMenuActive('offers')}}>{t('catalog.offer')}</li>
                                        </ul>
                                    </div>
                                </CSSTransition>

                                <CSSTransition
                                    in={subMenuActive == 'teams'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div className={`${Style.submenu} fade-enter`}>
                                        <div className={Style.subMenuTitleCont}>
                                            <img src={require('../../assets/img/arrow_right_2.svg').default} alt="back" className={Style.backArrow} onClick={() => {setSubMenuActive('parent')}} />
                                            <h3>{t('menu.team')}</h3>
                                        </div>

                                        <div className={Style.subMenuInner}>
                                            {filters.teams && Array.isArray(filters.teams) && selectedFilters.teams && Array.isArray(selectedFilters.teams) && (
                                                <ul>
                                                    {filters.teams.map((term) => (
                                                        <li key={term.slug}>
                                                            <CustomCheckbox label={term.name} term={term.slug} tax="teams" checked={(selectedFilters.teams.includes(term.slug))} onChange={handleFilterChange} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </CSSTransition>

                                <CSSTransition
                                    in={subMenuActive == 'drivers'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div className={`${Style.submenu} fade-enter`}>
                                        <div className={Style.subMenuTitleCont}>
                                            <img src={require('../../assets/img/arrow_right_2.svg').default} alt="back" className={Style.backArrow} onClick={() => {setSubMenuActive('parent')}} />
                                            <h3>{t('menu.driver')}</h3>
                                        </div>

                                        <div className={Style.subMenuInner}>
                                            {filters.drivers && Array.isArray(filters.drivers) && selectedFilters.drivers && Array.isArray(selectedFilters.drivers) && (
                                                <ul>
                                                    {filters.drivers.map((term) => (
                                                        <li key={term.slug}>
                                                            <CustomCheckbox label={term.name} term={term.slug} tax="drivers" checked={(selectedFilters.drivers.includes(term.slug))} onChange={handleFilterChange} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </CSSTransition>

                                <CSSTransition
                                    in={subMenuActive == 'items'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div className={`${Style.submenu} fade-enter`}>
                                        <div className={Style.subMenuTitleCont}>
                                            <img src={require('../../assets/img/arrow_right_2.svg').default} alt="back" className={Style.backArrow} onClick={() => {setSubMenuActive('parent')}} />
                                            <h3>{t('menu.item')}</h3>
                                        </div>

                                        <div className={Style.subMenuInner}>
                                            {filters.types && Array.isArray(filters.types) && selectedFilters.types && Array.isArray(selectedFilters.types) && (
                                                <ul>
                                                    {filters.types.map((term) => (
                                                        <li key={term.slug}>
                                                            <CustomCheckbox label={term.name} term={term.slug} tax="types" checked={(selectedFilters.types.includes(term.slug))} onChange={handleFilterChange} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </CSSTransition>

                                <CSSTransition
                                    in={subMenuActive == 'colours'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div className={`${Style.submenu} fade-enter`}>
                                        <div className={Style.subMenuTitleCont}>
                                            <img src={require('../../assets/img/arrow_right_2.svg').default} alt="back" className={Style.backArrow} onClick={() => {setSubMenuActive('parent')}} />
                                            <h3>{t('menu.colour')}</h3>
                                        </div>

                                        <div className={Style.subMenuInner}>
                                            {filters.pa_colour && Array.isArray(filters.pa_colour) && selectedFilters.pa_colour && Array.isArray(selectedFilters.pa_colour) && (
                                                <ul>
                                                    {filters.pa_colour.map((term) => (
                                                        <li key={term.slug}>
                                                            <CustomCheckbox label={term.name} term={term.slug} tax="pa_colour" checked={(selectedFilters.pa_colour.includes(term.slug))} onChange={handleFilterChange} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </CSSTransition>

                                <CSSTransition
                                    in={subMenuActive == 'offers'}
                                    timeout={500}
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div className={`${Style.submenu} fade-enter`}>
                                        <div className={Style.subMenuTitleCont}>
                                            <img src={require('../../assets/img/arrow_right_2.svg').default} alt="back" className={Style.backArrow} onClick={() => {setSubMenuActive('parent')}} />
                                            <h3>{t('catalog.offer')}</h3>
                                        </div>

                                        <div className={Style.subMenuInner}>
                                            <ul>
                                                <li key="on-sale">
                                                    <CustomCheckbox label={t('catalog.on-sale')} term='on_sale' tax="offers" checked={(category == 'sale')} onChange={handleFilterChange} />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </CSSTransition>

                                <button onClick={(e) => handleClickOffFilters(e)} className={`${Theme.btnDarkMedium} ${Style.viewItemsBtn}`}>{t('catalog.view-items')}</button>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            }
        </>
    )
}