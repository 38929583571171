import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../services/woocom';


export const getProducts = createAsyncThunk('products/getProducts', async ({limit, page, filter, lastProductId, searchTerm}) => {

    var params = {
        limit: limit,
        page: page,
        filter: filter,
        lastProductId: lastProductId,
        searchTerm: searchTerm
    }

    const response = await client.get('/wc-products', {params});
    return response.data;
})


export const getFilterTerms = createAsyncThunk('filters/getTerms', async ({taxonomies}) => {

    var params = {
        taxonomies: taxonomies
    }

    const response = await client.get('/wp-taxonomies', {params});
    return response.data;
})


export const productCatalogSlice = createSlice({
    name: 'productCatalog',
    initialState: {
        products: [],
        productsLoading: false,
        endOfProducts: false,
        page: 1,
        filters: [],
        filtersLoading: false
    },
    reducers: {
    },
    extraReducers: builder => {
        builder

            // get products
            .addCase(getProducts.pending, (state, action) => {
                state.productsLoading = true;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                if (action.payload.page > state.page) {
                    state.products = [...state.products, ...action.payload.products];
                }
                else {
                    state.products = action.payload.products;
                }
                state.page = action.payload.page;
                if (action.payload.products.length === 0) {
                    state.endOfProducts = true;
                }
                else {
                    state.endOfProducts = false;
                }
                state.productsLoading = false;
            })

            // get filter terms
            .addCase(getFilterTerms.pending, (state, action) => {
                state.filtersLoading = true;
                state.filters = [];
            })
            .addCase(getFilterTerms.fulfilled, (state, action) => {
                state.filtersLoading = false;
                console.log(action.payload);
                state.filters = action.payload;
            })
    }
})

export default productCatalogSlice;