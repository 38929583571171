import React from "react";
import { useSelector } from "react-redux";
import OrderItemSingle from "./OrderSingleItem";
import Style from "./OrderItems.module.css";

export default function OrderItems() {

    const {lineItems} = useSelector(state => state.cart);

    return (
        <div className={Style.cont}>
            {lineItems ? lineItems.map((lineItem, index) => (
                <OrderItemSingle lineItem={lineItem} />
            )) :
                <div>
                    <p>You have no items in your cart currently</p>
                </div>
            }
        </div>
    );
}