import React from "react";
import Style from "./Reviews.module.css";
import Theme from "../../theme.module.css";
import Content from "../../layouts/Content";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ContentSwiper from "../contentSwiper/ContentSwiper";

export default function Reviews() {

    const {t, i18n} = useTranslation('common');
    const {isMobile} = useSelector(state => state.mobile);

    const reviewSlides = [];

    // for loop up to 5
    for (let i = 1; i <= 5; i++) {
        reviewSlides.push(
            <div className={Style.review}>
                <img src={require('../../assets/img/stars_5.svg').default} alt="rating-stars"/>
                <h4>{t('review-' + i + '.title')}</h4>
                <p className={Style.reviewMain}>{t('review-' + i + '.main')}</p>
                <p className={Style.reviewDate}>{t('review-' + i + '.date')}</p>
            </div>
        )
    }

    return (
        <div className={`${isMobile && Style.mobile} ${Style.reviews}`}>
            <Content>
                <div class={!isMobile && Theme.flexRow}>
                    {!isMobile &&
                        <h3>{t('home.reviews')}</h3>
                    }
                    <ContentSwiper swiperSlides={reviewSlides} numSlides={isMobile ? 1 : 3} classNames="hide-controls" autoLoop={true}/>
                </div>
            </Content>
        </div>
    );
}