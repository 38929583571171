import React from "react";
import { useSelector } from "react-redux";
import OrderItemSingle from "./OrderSingleItem";
import Style from "./OrderItems.module.css";

export default function OrderItems({order}) {

    return (
        <div className={Style.cont}>
            {order.lineItems && order.lineItems.map((lineItem, index) => (
                <OrderItemSingle lineItem={lineItem} />
            ))}
        </div>
    );
}