import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { CurrencyFormat } from "../internationalisation/internationalisation";
import { useTranslation } from 'react-i18next';


export default function OrderSubTotals() {

    const {subTotal, shipping, tax, discount} = useSelector(state => state.cart);
    const {currentCurrency, pricesInclusiveTax} = useSelector(state => state.internationalisation);
    const {taxRate} = useSelector(state => state.shippingRates);
    const {t, i18n} = useTranslation('common');
    const [displayShippingTotal, setDisplayShippingTotal] = useState(shipping);
    const [displaySubtotal, setDisplaySubtotal] = useState(subTotal);

    // Set display shipping total
    useEffect(() => {

        if (!taxRate || !shipping) {
            return;
        }

        let newShippingTotal = parseFloat(shipping);

        if (pricesInclusiveTax && taxRate.rate && taxRate.rate > 0) {
            let shippingTax = parseFloat(newShippingTotal / 100 * parseFloat(taxRate.rate));
            newShippingTotal = newShippingTotal + shippingTax;
        }

        setDisplayShippingTotal(newShippingTotal);
    }, [shipping, taxRate])

    // Set display subtotal
    useEffect(() => {

        if (!taxRate || !subTotal) {
            return;
        }

        let newSubtotal = parseFloat(subTotal);

        if (pricesInclusiveTax && taxRate.rate && taxRate.rate > 0) {
            let lineItemTax = parseFloat(newSubtotal / 100 * parseFloat(taxRate.rate));
            newSubtotal = newSubtotal + lineItemTax;
        }

        setDisplaySubtotal(newSubtotal);
    }, [subTotal, taxRate])

    return (
        <div className="orderSubTotals">
            <table>
                <tr>
                    <td>{t('cart.sub-total')}</td>
                    <td>{subTotal && CurrencyFormat(displaySubtotal, currentCurrency)}</td>
                </tr>
                {discount && 
                    <tr>
                        <td>{t('cart.discount')}</td>
                        <td>{CurrencyFormat(discount, currentCurrency)}</td>
                    </tr>
                }
                <tr>
                    <td>{t('cart.shipping')}</td>
                    <td>{shipping && CurrencyFormat(displayShippingTotal, currentCurrency)}</td>
                </tr>
                <tr>
                    <td>{t('cart.tax')} {pricesInclusiveTax && <span className="taxIncTax">{t('cart.inc-tax')}</span>}</td>
                    <td>{tax && tax !== 0 ? CurrencyFormat(tax, currentCurrency) : ''}</td>
                </tr>
            </table>
        </div>
    );
}