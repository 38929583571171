import React from 'react';
import Content from '../layouts/Content';
import ProductSingleDetails from '../features/productSingle/ProductSingleDetails';
import { useSelector } from 'react-redux';


export default function ProductSingle() {

    const {isMobile, deviceDetermined} = useSelector(state => state.mobile);

    return (
        <section>
            {deviceDetermined && 
                <>
                {!isMobile  ? (
                    <Content>
                        <ProductSingleDetails />
                    </Content>
                ) : (
                    <ProductSingleDetails />
                )}
                </>
            }
        </section>
    )
}