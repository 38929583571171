import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../services/woocom.js';
import { updateCart } from '../cart/cartSlice.js';
import { useElements } from '@stripe/react-stripe-js';


export const resolveStripeEvent = createAsyncThunk('stripe/resolveStripeEvent', async ({ stripeEvent, elements}, { getState }) => {

    // get new shippingRates and cart from state
    const shippingRates = await getState().shippingRates.shippingRates;
    const cart = await getState().cart;

    // update elements total
    let elementsResp = await elements.update({
        amount: Math.round(cart.total * 100),
    })

    const payload = {
        shippingRates: prepareShippingRates(shippingRates)
    };

    await stripeEvent.resolve(payload);

    return true;
});


/**
 * Prepare Shipping rates for Stripe
 * 
 * @param {array} shippingRates
 * @returns {array} preparedShippingRates 
 */
export const prepareShippingRates = (shippingRates) => {

    let preparedShippingRates = [];

    shippingRates.forEach((rate) => {

        let amount = 0;
        if (rate.cost) {
            amount = parseInt(rate.cost) * 100;
        }

        let preparedRate = {
            id: rate.id,
            displayName: rate.title,
            amount: amount
            // deliveryEstimate: {
            //     maximum: {unit: 'day', value: rate.deliveryEstimate.maximum.value},
            //     minimum: {unit: 'day', value: rate.deliveryEstimate.minimum.value}
            // }
        }

        preparedShippingRates.push(preparedRate);
    });

    return preparedShippingRates;
}


// export const stripeSlice = createSlice({
//     name: 'stripe',
//     initialState: {
//         loading: false
//     },
//     reducers: {
//     },
//     extraReducers: builder => {
//     }
// })


// export default stripeSlice;