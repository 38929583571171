import React from "react";
import { Link } from "react-router-dom";
import Content from "../Content";
import Theme from "../../theme.module.css";
import Style from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Subscribe from "../../features/subscribe/Subscribe";

function Footer() {

    const {t, i18n} = useTranslation('common');
    const {isMobile} = useSelector(state => state.mobile);

    return (
        <footer className={`${isMobile && Style.mobile} ${Style.footer}`}>
            <Subscribe />
            <Content>
                {!isMobile &&
                    <div className={`${Theme.flexRow} ${Style.upperCont}`}>
                        <div className={Style.logoCont}>
                            <a href="/" className={Style.logo}>
                                <img src={require('../../assets/img/The_Race_Works_Logo_Black.svg').default} alt="logo"/>
                            </a>
                        </div>
                        <div className={Style.col}>
                            <h4>{t('footer.info')}</h4>
                            <ul>
                                <li><Link to="/delivery-information">{t('footer.delivery')}</Link></li>
                                <li><Link to="/faqs">{t('faqs.title')}</Link></li>
                                <li><Link to="/returns">{t('footer.returns')}</Link></li>
                                <li><Link to="/contact">{t('footer.contact-us')}</Link></li>
                                <li><Link to="/privacy-policy">{t('footer.privacy-policy')}</Link></li>
                                <li><Link to="/terms-of-use">{t('footer.terms')}</Link></li>
                            </ul>
                        </div>
                        <div className={Style.col}>
                            <h4>{t('footer.customer')}</h4>
                            <ul>
                                <li><Link to="/my-account">{t('footer.my-account')}</Link></li>
                                <li><Link to="/cart">{t('footer.shopping-cart')}</Link></li>
                            </ul>
                        </div>
                        <div className={Style.col}>
                            <h4>{t('footer.shop')}</h4>
                            <ul>
                                <li><Link to="/catalog/t_shirts_polos">{t('items.tshirts')}</Link></li>
                                <li><Link to="/catalog/caps_headwear">{t('items.caps')}</Link></li>
                                <li><Link to="/catalog/jackets_sweaters">{t('items.jackets')}</Link></li>
                            </ul>
                        </div>
                    </div>
                }

                <div className={`${Theme.flexRow} ${Style.bottomCont}`}>
                    <div>
                        <p className={Style.copyright}>© TheRaceWorks 2024</p>
                        <p className={Style.companyInfo}>{t('footer.company-trading-name')} Redirect Marketing LTD | {t('footer.company-reg-no')} 7636614</p>
                        <p className={Style.companyInfo}>Norman House, 15 Stephenson Way, Three Bridges, Crawley, RH10 1TN, United Kingdom</p>
                        <p className={Style.gnarLink}><a href="https://www.gnar.co.uk" rel="nofollow">{t('footer.website-by')}</a></p>
                    </div>
                    
                    <div className={Style.socialIcons}>

                    </div>
                </div>

            </Content>
        </footer>
    );
}

export default Footer;