import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { localeRedirect } from "./internationalisation";
import Style from './LanguageSelect.module.css';
import { localeData } from './localeData';
import { setLanguage } from "./internationalisationSlice";


export default function LanguageSelect() {

    const dispatch = useDispatch();
    const { currentLanguage, localeCode } = useSelector(state => state.internationalisation);
    const {t, i18n} = useTranslation('common');
    const [selectedLocaleData, setSelectedLocaleData] = useState({});

    useEffect(() => {
        if (!localeCode) {
          return;
        }
    
        // Set native language
        const localeDataObj = localeData.find((localeDataObj) => localeDataObj.localeCode === localeCode);
        setSelectedLocaleData(localeDataObj);
    }, [localeCode]);

    // redirects to correct sub-domain / locale
    function handleCountryChange(event) {
        const locale = event.target.value;
        localeRedirect(locale);
    }

    // changes the language locally
    function handleLanguageChange(event) {
        const language = event.target.value;
        i18n.changeLanguage(language);
        dispatch(setLanguage(language));
    }

    return (
        <>
            <div>
                <p style={{textAlign: "left"}}>{t('preferences.region')}</p>
                {localeCode && (
                <select onChange={handleCountryChange} value={localeCode} className={Style.languageSelect}>
                    <option key="en-UK" value="UK">{t('countries.united-kingdom')}</option>
                    <option key="en-US" value="US">{t('countries.united-states')}</option>
                    <option key="en-AU" value="AU">{t('countries.australia')}</option>
                    <option key="en-NZ" value="NZ">{t('countries.new-zealand')}</option>
                    <option key="nl-NL" value="NL">{t('countries.netherlands')}</option>
                    <option key="es-ES" value="ES">{t('countries.spain')}</option>
                    <option key="fr-FR" value="FR">{t('countries.france')}</option>
                    <option key="it-IT" value="IT">{t('countries.italy')}</option>
                    <option key="pt-PT" value="PT">{t('countries.portugal')}</option>
                    <option key="de-DE" value="DE">{t('countries.germany')}</option>
                    <option key="en-IE" value="IE">{t('countries.ireland')}</option>
                </select>
                )}
            </div>

            <div>
                <p style={{textAlign: "left"}}>{t('preferences.language')}</p>
                {localeCode && selectedLocaleData && selectedLocaleData.languageCode && (
                <select onChange={handleLanguageChange} value={currentLanguage} className={Style.languageSelect}>

                    {(selectedLocaleData.languageCode !== 'en') && <option value="en">English</option>}
                    <option value={selectedLocaleData.languageCode}>{selectedLocaleData.languageName}</option>

                </select>
                )}
            </div>
        </>
    )
}