import React from "react";
import Style from "./CatalogMobileButtons.module.css";
import { useTranslation } from "react-i18next";


export default function CatalogMobileButtons({setFilterMenuActive, selectedFilters, setSelectedFilters}) {

    const {t, i18n} = useTranslation('common');

    // update selectedFilters when orderby is changed
    function handleOrderbyChange(e) {

        let newSelectedFilters = {...selectedFilters};
        newSelectedFilters.orderby = e.target.value;
        console.log('orderby changed: ' + e.target.value);

        setSelectedFilters(newSelectedFilters);
    }

    return (
        <div className={Style.cont}>
            <select onChange={handleOrderbyChange}>
                <option value="newest">{t('catalog.sort-by-newest')}</option>
                <option value="price-asc">{t('catalog.sort-by-cheapest')}</option>
                <option value="price-desc">{t('catalog.sort-by-most-expensive')}</option>
            </select>
            <a onClick={() => setFilterMenuActive(true)}>{t('catalog.filters')}</a>
        </div>
    )
}