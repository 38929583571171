import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOrders } from "../order/ordersSlice";
import Collapsible from "../collapsible/Collapsible";
import { useTranslation } from "react-i18next";
import OrderRecievedMain from "../order/OrderRecievedMain";
import "./MyAccountOrders.css";

export default function MyAccountOrders() {

    const {user, logged_in, error} = useSelector(state => state.user);
    const {orders} = useSelector(state => state.orders);
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation('common');

    useEffect(() => {
        console.log('should be getting orders');
        if (logged_in) {
            dispatch(getOrders());
        }
    }, [logged_in]);

    return (
        <div className="my-account-my-orders">
            <h3>My orders</h3>

            {orders && orders.map(order => {
                return (
                    <Collapsible title={`${t('my-account.order')} ${order.number} | ${order.date}`} key={order.id}>
                        <OrderRecievedMain order={order} />
                    </Collapsible>
                )
            })}

        </div>
    );
}