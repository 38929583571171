import React from "react";
import { useTranslation } from 'react-i18next';
import Content from "../layouts/Content";
import Style from "./LegalPage.module.css";
import { useSelector } from "react-redux";


export default function Privacy() {

    const {t, i18n} = useTranslation('common');
    const {localeCode} = useSelector(state => state.internationalisation);
    const dotComLocales = ['US', 'UK', 'NZ', 'AU'];

    let translatedHTML;
    
    // .com sites
    if (dotComLocales.includes(localeCode)) {
        translatedHTML = t('legal.privacy-dot-com-1');
        translatedHTML += t('legal.privacy-dot-com-2');
        translatedHTML += t('legal.privacy-dot-com-3');
        translatedHTML += t('legal.privacy-dot-com-4');
        translatedHTML += t('legal.privacy-dot-com-5');
        translatedHTML += t('legal.privacy-dot-com-6');
        translatedHTML += t('legal.privacy-dot-com-7');
        translatedHTML += t('legal.privacy-dot-com-8');
        translatedHTML += t('legal.privacy-dot-com-9');
    }

    // EU sites
    else {
        translatedHTML = t('legal.privacy-1');
        translatedHTML += t('legal.privacy-2');
        translatedHTML += t('legal.privacy-3');
        translatedHTML += t('legal.privacy-4');
        translatedHTML += t('legal.privacy-5');
        translatedHTML += t('legal.privacy-6');
        translatedHTML += t('legal.privacy-7');
    }

    translatedHTML = {__html:translatedHTML}

    return (
        <div className={Style.legalPageCont}>
            <Content>
                <h1>{t('legal.privacy-title')}</h1>
                <div dangerouslySetInnerHTML={translatedHTML}>

                </div>
            </Content>
        </div>
    );
}