import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Theme from "../../theme.module.css";
import Style from "./HeaderCart.module.css";


export default function HeaderCart() {

    const navigate = useNavigate();
    const { isMobile } = useSelector(state => state.mobile);

    function handleGoBack() {
        navigate(-1);
    }

    return (
        <>
            {!isMobile &&
                <div className={`${Theme.flexRow} ${Style.headerCart} ${isMobile && Style.mobile}`}> 
                    <img src={require('../../assets/img/trw_logo_black_stacked.png')} alt="logo"/>
                </div>
            }
            {isMobile &&
                <a onClick={handleGoBack}>
                    <img src={require('../../assets/img/arrow_right_2.svg').default} alt="back" className={Style.backArrow} />
                </a>
            }
        </>
    );
}