import React from "react";
import Style from "./ProductSingleBreadcrumbs.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ProductSingleBreadcrumbs({crumbs}) {

    const {t, i18n} = useTranslation('common');

    return (
        <div className={Style.breadcrumbs}>
            <Link to="/">{t('breadcrumbs.home')}</Link>
            {crumbs.map((crumb, index) => (
                <Link to={crumb.link}>{crumb.text}</Link>
            ))}
        </div>
    );
}