import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRates, updateAvailableRates } from "./shippingRatesSlice";
import { getCartFromLocalStorage, updateCart } from "../cart/cartSlice";
import { CurrencyFormat } from "../internationalisation/internationalisation";
import "./ShippingRates.css";

export default function ShippingRates() {

    const dispatch = useDispatch();
    const {shippingRates, availableRates} = useSelector(state => state.shippingRates);
    const {subTotal, tax} = useSelector(state => state.cart);
    const {currentLanguage} = useSelector(state => state.internationalisation);
    const {taxRate} = useSelector(state => state.shippingRates);
    const {currentCurrency, pricesInclusiveTax} = useSelector(state => state.internationalisation);
    let cartData = useSelector(state => state.cart);

    useEffect(() => {
        dispatch(getRates());
    }, [currentLanguage]);

    // determine available rates from shippingRates
    useEffect(() => {
        if (shippingRates) {
            let newAvailableRates = [];
            let cartTotalExShipping = parseFloat(subTotal);

            if (tax) {
                cartTotalExShipping += parseFloat(tax);
            }

            let freeShippingAvailable = false;

            shippingRates.forEach((rate) => {
                console.log('considering shipping rate', rate, cartTotalExShipping);

                // qualify rate
                try {
                    switch (rate.type) {

                        case 'flat_rate':
                            newAvailableRates.push(rate);
                            break;

                        case 'free_shipping':
                            if (rate.requires == 'min_amount' && rate.min_amount !== null) {
                                if (cartTotalExShipping >= rate.min_amount) {
                                    newAvailableRates.push(rate);
                                    freeShippingAvailable = true;
                                    break;
                                }
                            }
                            break;

                        case 'local_pickup':
                            newAvailableRates.push(rate);
                            break;

                        case 'table_rate':
                            rate.rates.forEach((tableRate) => {
                                if (!tableRate.rate_condition) {
                                    let newRate = {
                                        id: rate.id,
                                        tableRateID: tableRate.rate_id,
                                        title: tableRate.rate_label,
                                        cost: tableRate.rate_cost
                                    }
                                    
                                    newAvailableRates.push(newRate);
                                }

                                if (tableRate.rate_condition == 'price' && rate.rate_min !== null && tableRate.rate_max !== null) {
                                    if (cartTotalExShipping >= tableRate.rate_min && cartTotalExShipping <= tableRate.rate_max) {
                                        // add the rate manually
                                        let newRate = {
                                            id: rate.id,
                                            tableRateID: tableRate.rate_id,
                                            title: tableRate.rate_label,
                                            cost: tableRate.rate_cost
                                        }

                                        newAvailableRates.push(newRate);
                                    }
                                }
                            })
    
                            break;
                    }
                } catch (error) {
                    console.log(error);
                }

            });

            console.log('new available rates', newAvailableRates);

            // Exclude certain rates
            newAvailableRates = newAvailableRates.filter((rate) => {

                // if free shipping is available remove all other rates (except UK express)
                if (freeShippingAvailable) {
                    if (rate.type !== 'free_shipping') {

                        // Allow UK Express
                        if (rate.id == 'flat_rate:41') {
                            return true;
                        }

                        return false;
                    }
                }

                return true;
            });

            console.log('new available rates after exclusion', newAvailableRates);

            // update available rates in slice here
            dispatch(updateAvailableRates(newAvailableRates));
        }
    }, [shippingRates, subTotal]);

    // Update cart with new default shipping rate when shipping rates change
    useEffect(() => {
        console.log('available rates or cart changed', availableRates);
        if (availableRates) {
            let setDefault = true;

            // if selected shipping rate is an option in the new shipping rates, keep it
            if (cartData.selectedShippingRate) {
                console.log('selected shipping rate', cartData.selectedShippingRate);
                console.log('selected table rate', cartData.selectedTableShippingRate);
                Object.keys(availableRates).forEach((key) => {
                    if (availableRates[key].id === cartData.selectedShippingRate) {

                        // is table rate, check if the table rate is already selected
                        if (cartData.selectedTableShippingRate && availableRates[key].tableRateID) {
                            if (parseInt(availableRates[key].tableRateID) === parseInt(cartData.selectedTableShippingRate)) {
                                console.log('should keep the table rate');
                                setDefault = false;
                                return false;
                            }
                        }

                        // is not table rate
                        else {
                            console.log('should keep the rate');
                            setDefault = false;
                            return false;
                        }
                    }
                });
            }

            if (setDefault) {
                setDefaultShippingRate();
            }
        }
    }, [availableRates, cartData]);


    function setDefaultShippingRate() {
        let defaultShippingRate;

        console.log('should be setting default rate');

        // if availableRates is an array, set the first rate as default
        if (Array.isArray(availableRates)) {
            defaultShippingRate = availableRates[0];
        }

        // else if is an object, set the first as default
        else if (typeof availableRates === 'object' && availableRates !== null) {
            let defaultShippingRateKey = Object.keys(availableRates)[0];
            defaultShippingRate = availableRates[defaultShippingRateKey];
        }
        
        // else if is a single rate use it
        else {
            defaultShippingRate = availableRates;
        }

        if (defaultShippingRate === undefined) {
            console.log('no default rate available');
            return false;
        }

        console.log('default shipping rate', defaultShippingRate);

        let defaultShippingRateID = defaultShippingRate.id;
        let defaultTableShippingRateID = '';

        // handle table rates
        if (defaultShippingRate.tableRateID) {
            defaultTableShippingRateID = defaultShippingRate.tableRateID;
            console.log('should set the default table rate id', defaultTableShippingRateID);
        }

        // get cart from local storage if it's not yet initialised
        if (cartData.lineItems) {
            cartData = getCartFromLocalStorage();
        }

        const newCartData = {
            ...cartData,
            selectedShippingRate: defaultShippingRateID,
            selectedTableShippingRate: defaultTableShippingRateID
        };
        
        dispatch(updateCart({ 
            cartData: newCartData,
            taxRate: taxRate
        }));
    }


    function handleShippingSelect(e) {

        let selectedShippingRateID = e.target.value;
        let selectedTableShippingRateID = e.target.getAttribute('tablerateid');

        let newCartData = {
            ...cartData,
            selectedShippingRate: selectedShippingRateID,
            selectedTableShippingRate: selectedTableShippingRateID
        };

        dispatch(updateCart({cartData: newCartData}));
    }


    return (
        <div className="shippingRates">
            <ul>
                {availableRates && cartData.selectedShippingRate && cartData.selectedShippingRate !== '' &&
                    <>
                    {availableRates && Array.isArray(availableRates) ? (
                        availableRates.map((rate, index) => (
                            <label key={index}>
                                <p>{rate.title}</p> 
                                <span>
                                    {rate.cost ? 
                                        <>
                                            {pricesInclusiveTax && taxRate ? 
                                                CurrencyFormat(parseFloat(rate.cost) + ((parseFloat(rate.cost) / 100) * taxRate.rate), currentCurrency)
                                                :
                                                CurrencyFormat(rate.cost, currentCurrency)
                                            }
                                        </>
                                        :
                                        'Free'
                                    }
                                </span>
                                {rate.tableRateID ? (
                                    <input
                                        type="radio"
                                        name="shippingSelect"
                                        onChange={handleShippingSelect}
                                        value={rate.id}
                                        tableRateID={rate.tableRateID}
                                        checked={cartData.selectedShippingRate === rate.id}
                                    />
                                ) : (
                                    <input
                                        type="radio"
                                        name="shippingSelect"
                                        onChange={handleShippingSelect}
                                        value={rate.id}
                                        checked={cartData.selectedShippingRate === rate.id}
                                    />
                                )}
                            </label>
                        ))
                    ) : (
                        Object.keys(availableRates).map((key) => {
                            const rate = availableRates[key];
                            return (
                                <label key={key}>
                                    <p>{rate.title}</p> 
                                    <span>
                                    {rate.cost ? 
                                        <>
                                            {pricesInclusiveTax && taxRate ? 
                                                CurrencyFormat(parseFloat(rate.cost) + ((parseFloat(rate.cost) / 100) * taxRate.rate), currentCurrency)
                                                :
                                                CurrencyFormat(rate.cost, currentCurrency)
                                            }
                                        </>
                                        :
                                        'Free'
                                    }
                                    </span>
                                    {rate.tableRateID ? (
                                        <input
                                            type="radio"
                                            name="shippingSelect"
                                            onChange={handleShippingSelect}
                                            value={rate.id}
                                            tableRateID={rate.tableRateID}
                                            checked={cartData.selectedShippingRate === rate.id}
                                        />
                                    ) : (
                                        <input
                                            type="radio"
                                            name="shippingSelect"
                                            onChange={handleShippingSelect}
                                            value={rate.id}
                                            checked={cartData.selectedShippingRate === rate.id}
                                        />
                                    )}
                                </label>
                            );
                        })
                    )}
                    </>
                }
            </ul>
        </div>
    );
}