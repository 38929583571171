import React, {useState} from "react";
import Style from "./QuantitySelect.module.css";


export default function QuantitySelect({initialValue, onQuantityChange, cartItemKey}) {

    const [quantity, setQuantity] = useState(initialValue);

    function handleQuantityChange(e) {
        var newQty = parseInt(e.target.value);
        setQuantity(newQty);

        if (cartItemKey) {
            onQuantityChange(cartItemKey, newQty);
        } else {
            onQuantityChange(newQty);
        }
    }
    
    function decrementQuantity() {
        if (quantity > 1) {
            var newQty = quantity-1;
            setQuantity(newQty);

            if (cartItemKey) {
                onQuantityChange(cartItemKey, newQty);
            } else {
                onQuantityChange(newQty);
            }
        }
    }

    function incrementQuantity() {
        var newQty = quantity+1;
        setQuantity(newQty);
        
        if (cartItemKey) {
            onQuantityChange(cartItemKey, newQty);
        } else {
            onQuantityChange(newQty);
        }
    }

    return (
        <div className={Style.quantitySelect}>
            <a onClick={decrementQuantity}>-</a>
            <input type="number" step={1} value={quantity} onChange={handleQuantityChange} />
            <a onClick={incrementQuantity}>+</a>
        </div>
    )
}