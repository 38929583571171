import React, {useState} from "react";
import './CustomCheckbox.css';


export default function CustomCheckbox({label, checked, onChange, term, tax}) {

    const [isChecked, setIsChecked] = useState(checked);

    function handleOnChange() {
        let newChecked = !isChecked;
        setIsChecked(newChecked);
        onChange({term, tax, newChecked});
    }

    return (
        <label className={`customCheckbox ${isChecked ? 'isChecked' : ''}`}>
            {label}
            <span class="customCheckboxBox">

            </span>
            <input type="checkbox" checked={isChecked} onChange={handleOnChange} />
        </label>
    );

}