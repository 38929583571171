import React, { useEffect, useState } from 'react';
import Content from '../layouts/Content';
import { getCartFromLocalStorage, updateCart } from '../features/cart/cartSlice';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import OrderRecievedMain from '../features/order/OrderRecievedMain';
import { Link, useParams } from 'react-router-dom';
import { getOrder } from '../features/order/ordersSlice';
import gs_dataLayer from '../gs_modules/dataLayer';
import FBTracking from '../services/facebook-tracking';
import './OrderRecieved.css';


export default function OrderRecieved() {

    const {t, i18n} = useTranslation('common');
    const params = useParams();
    const dispatch = useDispatch();
    const {order} = useSelector(state => state.orders);
    const {isMobile} = useSelector(state => state.mobile);
    const {localeCode} = useSelector(state => state.internationalisation);
    const [datalayerTriggered, setDatalayerTriggered] = useState(false);
    const [redirectStatus, setRedirectStatus] = useState(null);

    useEffect(() => {
        const orderID = params.ordernumber;
        console.log(orderID);

        const queryParams = new URLSearchParams(window.location.search);
        let clientSecret = queryParams.get('payment_intent_client_secret');
        let redirectStatus = queryParams.get('redirect_status');

        if (!redirectStatus) {
            setRedirectStatus('na');
        } else {
            setRedirectStatus(redirectStatus);
        }

        if (!orderID) {
            return
        }

        dispatch(getOrder({orderID, clientSecret}));

        // empty cart
        localStorage.removeItem('cart');
        let cartData = getCartFromLocalStorage();
        dispatch(updateCart({cartData: cartData})); 
    }, []);

    // tracking - purchase
    useEffect(() => {
        if (!localeCode || !order || Object.keys(order).length === 0 || datalayerTriggered) {
            return
        }

        // datalayer
        gs_dataLayer.purchase(order, localeCode);
        setDatalayerTriggered(true);

        // facebook
        FBTracking.purchase(localeCode, order, 'WOO-' + order.id);
    }, [order, localeCode]);


    return (
        <>
            {redirectStatus &&
                <>
                    {redirectStatus !== 'failed' ? (
                        <>
                            <section style={{paddingTop: '30px'}} className={`${isMobile && 'mobile'} order-recieved-cont`}>
                                <Content>
                                    <h1>{t('order-recieved.title')}</h1>
                                    {/* <p>{t('order-recieved.sub-title')}</p> */}
                                </Content>
                            </section>
                            <section>
                                <Content>
                                    <OrderRecievedMain order={order} />
                                </Content>
                            </section>
                        </>
                    ) : (
                        <>
                            <section style={{paddingTop: '30px'}} className={`${isMobile && 'mobile'} order-recieved-cont`}>
                                <Content>
                                    <h1>{t('order-recieved.problem')}</h1>
                                    <Link to="/checkout">{t('order-recieved.return-to-checkout')}</Link>
                                </Content>
                            </section>
                        </>
                    )}
                </>
            }
        </>
    );
}