import React from "react";
import Style from './HeaderTop.module.css';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LanguageModal from "../../features/internationalisation/LanguageModal";
import Theme from "../../theme.module.css";


export default function HeaderTop() {

    const {t, i18n} = useTranslation('common');
    const {isMobile} = useSelector(state => state.mobile);
    const {localeCode} = useSelector(state => state.internationalisation);
    const dotComLocales = ['US', 'UK', 'NZ', 'AU'];

    return (
        <div className={`${Style.headerTop} ${isMobile && Style.mobile}`}>
            <div className={`${Theme.content} ${Style.contentInner}`}>
                {localeCode &&
                    <>
                    {dotComLocales.includes(localeCode) ? (
                        <p>{t('header.store-notice-dot-com')}</p>
                    ) : (
                        <p>{t('header.store-notice')}</p>
                    )}
                    </>
                }
                
                {!isMobile &&
                    <LanguageModal />
                }
            </div>
        </div>
    );
}