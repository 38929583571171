import React from "react";
import { useTranslation } from "react-i18next";
import Faqs from "../features/faqs/Faqs";
import Content from "../layouts/Content";

export default function Questions() {

  const {t, i18n} = useTranslation('common');

  return(
    <section style={{paddingTop: '60px'}}>
      <Content>
        <h1>{t('faqs.title')}</h1>
        <Faqs />
      </Content>
    </section>
  );

}