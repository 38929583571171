import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts } from './productCatalogSlice';
import SingleCatalogProduct from './SingleCatalogProduct';
import "./ProductCatalogList.css";
import ProductCatalogChunk from './ProductCatalogChunk';
import { useTranslation } from "react-i18next";


export default function ProductCatalogList({selectedFilters}) {

    const dispatch = useDispatch();
    const {products, productsLoading, endOfProducts} = useSelector(state => state.productCatalog);
    const [searchTerm, setSearchTerm] = useState('');
    const numProdsPerChunk = 12;
    const moreProductsRef = useRef(null);
    const [numChunks, setNumChunks] = useState(0);
    const [nextPage, setNextPage] = useState(1);
    const {t, i18n} = useTranslation('common');
    const {isMobile} = useSelector(state => state.mobile);


    // handle get products
    function handleGetProducts(selectedFilters, pageNum) {

        console.log('getting prods for page: ' + pageNum);

        // get id of last product
        let lastProductId = 0;
        let lastProduct = products[products.length - 1];

        if (lastProduct) {
            lastProductId = lastProduct.id;
        }

        let params = {
            limit: 12,
            page: pageNum,
            filter: selectedFilters,
            lastProductId: lastProductId
        }

        dispatch(getProducts(params));
    }

    // calc num chunks
    useEffect(() => {
        if (products && products.length > 0) {
            let numChunks = Math.ceil(products.length / numProdsPerChunk);
            setNumChunks(numChunks);
            console.log('set num chunks: ' + numChunks);
        }
    }, [products]);

    // Intersection Observer
    useEffect(() => {
        const options = {
            root: null,
            threshold: 0.5,
        }

        const observer = new IntersectionObserver(handleMoreProductsIntersection, options);

        if (moreProductsRef.current) {
            observer.observe(moreProductsRef.current);
        }

        return () => {
            if (moreProductsRef.current) {
                observer.unobserve(moreProductsRef.current);
            }
        }
    }, [products, endOfProducts]);

    // handle more products intersection - set next page
    function handleMoreProductsIntersection(entries) {
        console.log('intersection observed');
        entries.forEach((entry) => {
            console.log(endOfProducts);

            if (entry.isIntersecting && productsLoading == false && products.length > 0 && !endOfProducts) {

                setNextPage(nextPage + 1);
            }
        })
    }

    // get more products when nextPage or filters changed
    useEffect(() => {
        if (!selectedFilters) {
            return;
        }

        if (nextPage == 0) {
            setNextPage(1);
            return;
        }

        console.log('getting prods because page changed');
        handleGetProducts(selectedFilters, nextPage);
    }, [selectedFilters, nextPage])

    // reset page to 1 when filters change
    useEffect(() => {
        //setHasIntersected(false);
        setNextPage(1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [selectedFilters]);


    return (
        <div className={`${isMobile && 'mobile'} productCatListOuter`}>
            <div className="productCatList">
                {products && products.length >= 1 ? (
                    <>
                    {(() => {
                        console.log('should output ' + numChunks + ' chunks');
                        const chunkComponents = [];
                        for (let i = 1; i <= numChunks; i++) {
                            chunkComponents.push(
                                <ProductCatalogChunk key={i} page={i} numProds={numProdsPerChunk} />
                            );
                        }
                        return chunkComponents;
                    })()}
                    <div ref={moreProductsRef}></div>
                    </>
                ) : (
                    <>
                    {!productsLoading &&
                        <div className="dots-cont">
                            <p>{t('catalog.no-products')}</p>
                        </div>
                    }
                    </>
                )}

                {productsLoading &&
                    // spinner
                    <div className="dots-cont">
                        <div className="dot-falling"></div>
                    </div>
                }
            </div>

        </div>
    );
}