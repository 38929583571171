import React, { useEffect } from "react"
import Theme from '../../theme.module.css';
import OrderSubTotals from './OrderSubTotals';
import OrderTotal from './OrderTotal';
import BillingAddress from './BillingAddress';
import ShippingAddress from './ShippingAddress';
import OrderItems from './OrderItems';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";


export default function OrderRecievedMain({order}) {

    const {t, i18n} = useTranslation('common');
    const {isMobile} = useSelector(state => state.mobile);

    useEffect(() => {
        // empty cart
        localStorage.removeItem('cart');
    }, []);


    return (
        <>
            {order ?
                <>
                    <h3>{t('order-recieved.your-order')}: </h3>
                    <h3></h3>
                    <div className={Theme.boxCont}>
                        <p>{t('order-recieved.order-number')}: #{order.number}</p>
                        <h4>{t('order-recieved.order-items')}:</h4>
                        <OrderItems order={order} />
                        <OrderSubTotals order={order} />
                        <OrderTotal order={order} /> 
                    </div>
                    <div className={`${Theme.flexRow} order-addresses ${isMobile && 'mobile'}`}>
                        <div className={Theme.boxCont}>
                            <h4>{t('order-recieved.billing-address')}</h4>
                            <BillingAddress order={order} />
                        </div>
                        <div className={Theme.boxCont}>
                            <h4>{t('order-recieved.shipping-address')}</h4>
                            <ShippingAddress order={order} />
                        </div>
                    </div>
                </>
            :
                <div className={Theme.loadingSpinner}></div>
            }   
        </>
    )
}