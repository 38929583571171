import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../services/woocom';

export const getProduct = createAsyncThunk('product/getProduct', async ({slug, event}) => {

    var params = {
        slug: slug,
        event: event
    }

    const response = await client.get('/wc-products', {params});
    return response.data;
})

export const productSingleSlice = createSlice({
    name: 'productSingle',
    initialState: {
        product: {},
        loading: false
    },
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(getProduct.pending, (state, action) => {
                state.loading = true;
                //state.product = {};
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.product = action.payload;
            })
    }
})

export default productSingleSlice;