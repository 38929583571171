import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import SingleCatalogProduct from "./SingleCatalogProduct";


export default function ProductCatalogChunk({page, numProds}) {

    const {products, productsLoading} = useSelector(state => state.productCatalog);
    const [chunkProducts, setChunkProducts] = useState(null);
    const {isMobile} = useSelector(state => state.mobile);

    // collect products for chunk
    useEffect(() => {
        if (!products || productsLoading) {
            return;
        }

        let chunk = [];

        // foreach product in products
        products.forEach((product, index) => {
            let startIndex = (page - 1) * numProds;
            let endIndex = page * numProds;

            if (index >= startIndex && index < endIndex) {
                chunk.push(product);
            }
        });

        setChunkProducts(chunk);

    }, [products]);


    // prep loading elements
    let loadingElements = [];

    for (let i = 0; i < 12; i++) {
        loadingElements.push(
            <div key={i} className="loadingSingleProd"></div>
        );
    }

    return (
        <div className={`${isMobile && 'mobile'} productChunk`}>
            {chunkProducts && 
                <>
                    {chunkProducts.map((product, index) => (
                        <SingleCatalogProduct key={index} product={product} />
                    ))}
                </>
            }
        </div>    
    );
}