
/**
 * @class gs_dataLayer
 * @classdesc Google Tag Manager Data Layer for headless woocommerce
 * @static
 */

class gs_dataLayer {

    /**
     * View single product
     * 
     * @param {Object} product
     * @param {String} currencyCode
     */
    static viewProduct(product, currencyCode) {

        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });

            let price;
    
            if (product.type == 'variable') {
                Object.values(product.variations).forEach(function (variation) {
                    price = variation.price;
                });
            } else {
                price = product.price;
            }
    
            let data = {
                'event': 'view_item',
                'ecommerce': {
                    'currency': currencyCode,
                    'value': price,
                    'items': [
                        {
                            'item_id': product.ID,
                            'item_name': product.name,
                            'item_category': product.category,
                            'price': price
                        }
                    ]
                }
            }
    
            window.dataLayer.push(data);
            console.log('DataLayer', data);

        } catch (error) {
            console.log(error);
        }
    }


    /**
     * Add to cart
     * 
     * @param {Object} product
     * @param {String} currencyCode
     * @param {Number} quantity
     */
    static addToCart(product, currencyCode, quantity) {

        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });

            let price;
    
            if (product.type == 'variable') {
                Object.values(product.variations).forEach(function (variation) {
                    price = variation.price;
                });
            } else {
                price = product.price;
            }

            let value = price * quantity;
    
            let data = {
                'event': 'add_to_cart',
                'ecommerce': {
                    'currency': currencyCode,
                    'value': value,
                    'items': [
                        {
                            'item_id': product.ID,
                            'item_name': product.name,
                            'item_category': product.category,
                            'price': price,
                            'quantity': quantity
                        }
                    ]
                }
            }

            window.dataLayer.push(data);
            console.log('DataLayer', data);

        } catch (error) {
            console.log(error);
        }
    }


    /**
     * Begin checkout
     * 
     * @param {Object} cartData
     * @param {String} currencyCode
     */
    static beginCheckout(cartData, currencyCode) {

        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });

            let value = Math.round(cartData.total * 100) / 100;
            let items = [];

            cartData.lineItems.forEach(function (item) {
                let price = item.price / item.quantity;

                items.push({
                    'item_id': item.product_id,
                    'item_name': item.product_name,
                    'item_category': item.category,
                    'price': Math.round(item.headlessPrice * 100) / 100,
                    'quantity': item.quantity
                });
            });
    
            let data = {
                'event': 'begin_checkout',
                'ecommerce': {
                    'currency': currencyCode,
                    'value': value,
                    'items': items
                }
            }
    
            window.dataLayer.push(data);
            console.log('DataLayer', data);

        } catch (error) {
            console.log(error);
        }
    }


    /**
     * Purchase
     * 
     * @param {Object} orderData
     */
    static purchase(orderData, localeCode) {
        
        console.log('Order data', orderData);

        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });

            let value       = Math.round(orderData.total * 100) / 100;
            let tax         = Math.round(orderData.totalTax * 100) / 100;
            let shipping    = Math.round(orderData.totalShipping * 100) / 100;
            let items       = [];

            orderData.lineItems.forEach(function (item) {
                let price = item.price / item.quantity;

                items.push({
                    'item_id': item.product_id,
                    'item_name': item.product_name,
                    'item_category': item.category,
                    'price': Math.round(item.headlessPrice * 100) / 100,
                    'quantity': item.quantity
                });
            });
    
            let data = {
                'event': 'purchase',
                'ecommerce': {
                    'transaction_id': orderData.id,
                    'affiliation': localeCode + ' website',
                    'currency': orderData.currency,
                    'value': value,
                    'tax': tax,
                    'shipping': shipping,
                    'items': items
                }
            }
    
            window.dataLayer.push(data);
            console.log('DataLayer', data);

        } catch (error) {
            console.log(error);
        }
    }
}

export default gs_dataLayer;