import { configureStore } from '@reduxjs/toolkit';
import cartSlice from '../features/cart/cartSlice';
import productCatalogSlice from '../features/productCatalog/productCatalogSlice';
import productSingleSlice from '../features/productSingle/productSingleSlice';
import internationalisationSlice from '../features/internationalisation/internationalisationSlice';
import ordersSlice from '../features/order/ordersSlice';
import userSlice from '../features/user/userSlice';
import shippingRatesSlice from '../features/shippingRates/shippingRatesSlice';
import mobileSlice from '../layouts/mobileSlice';


const store = configureStore({
    reducer: {
        cart: cartSlice.reducer,
        productCatalog: productCatalogSlice.reducer,
        productSingle: productSingleSlice.reducer,
        internationalisation: internationalisationSlice.reducer,
        orders: ordersSlice.reducer,
        user: userSlice.reducer,
        shippingRates: shippingRatesSlice.reducer,
        mobile: mobileSlice.reducer
    }
})

export default store