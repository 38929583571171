import React from 'react';
import Content from '../layouts/Content';
import './HomeHero.css';
import ContentSwiper from '../features/contentSwiper/ContentSwiper';
import ImageBox from '../features/imageBox/ImageBox';
import Reviews from '../features/reviews/Reviews';
import { useTranslation } from 'react-i18next';
import { teamsDrivers } from '../data/teams-drivers';
import ProductSlider from '../features/productSlider/ProductSlider';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Style from './Home.module.css';


export default function Home() {

    const {t, i18n} = useTranslation('common');
    const {isMobile} = useSelector(state => state.mobile);
    const teamSlides = [];
    const driverSlides = [];

    teamsDrivers.forEach((driver) => {

        // is team
        if (!driver.team) {
            try {
                teamSlides.push(
                    <ImageBox foregroundColor={driver.foregroundColor} image={require(`../assets/img/boxes/${driver.key}.svg`)} link={`/catalog/${driver.key}`} />
                )
            } catch (e) { }
        }

        // is driver
        else {
            try {
                driverSlides.push(
                    <ImageBox text="" image={require(`../assets/img/boxes/${driver.key}.svg`)} link={`/catalog/${driver.key}`} />
                )
            } catch (e) { }
        }
    });

    return (
        <>
            <Helmet>
                <link rel="alternate" hreflang="en-gb" href="https://www.theraceworks.com/" />
                <link rel="alternate" hreflang="en-us" href="https://us.theraceworks.com/" />
                <link rel="alternate" hreflang="en-au" href="https://au.theraceworks.com/" />
                <link rel="alternate" hreflang="en-nz" href="https://nz.theraceworks.com/" />
                <link rel="alternate" hreflang="nl-nl" href="https://nl.theraceworks.com/" />
                <link rel="alternate" hreflang="en-nl" href="https://en-nl.theraceworks.com/" />
                <link rel="alternate" hreflang="fr-fr" href="https://fr.theraceworks.com/" />
                <link rel="alternate" hreflang="en-fr" href="https://en-fr.theraceworks.com/" />
                <link rel="alternate" hreflang="de-de" href="https://de.theraceworks.com/" />
                <link rel="alternate" hreflang="en-de" href="https://en-de.theraceworks.com/" />
                <link rel="alternate" hreflang="es-es" href="https://es.theraceworks.com/" />
                <link rel="alternate" hreflang="en-es" href="https://en-es.theraceworks.com/" />
                <link rel="alternate" hreflang="pt-pt" href="https://pt.theraceworks.com/" />
                <link rel="alternate" hreflang="en-pt" href="https://en-pt.theraceworks.com/" />
                <link rel="alternate" hreflang="x-default" href="https://www.theraceworks.com/" />
            </Helmet>
            {!isMobile ? (
                <section className='homeHero' style={{backgroundImage: `url(${require('../assets/img/desktop-bg.jpg')})`}}>
                    <div className='heroContent'>
                        <Content classNames='height100'>
                            <div className='heroContentInner'>
                                <div className='leftCol'>
                                    <h1>{t('home.title')}</h1>
                                    <h3>{t('home.sub-title')}</h3>
                                </div>
                                <div className='rightCol'>
                                    <img src={require('../assets/img/trw-home-hero-prods-mob.png')} alt="" className='heroImg' />
                                </div>
                            </div>
                        </Content>
                    </div>
                </section>
            ): (
                <section className='homeHero mobile' style={{backgroundImage: `url(${require('../assets/img/mobile-bg.jpg')})`}}>
                    <div className='heroContent'>
                        <Content classNames='height100'>
                            <div className='heroContentInner'>
                                <div className='leftCol'>
                                    <h1>{t('home.title')}</h1>
                                    <h3>{t('home.sub-title')}</h3>
                                </div>
                                {/* <div className='rightCol'>
                                    <img src={require('../assets/img/desktop-prods.png')} alt="" className='heroImg' />
                                </div> */}
                            </div>
                        </Content>
                    </div>
                </section>
            )}
            <section className="heroTeamsSwiper">
                <Content>
                    <ContentSwiper swiperSlides={teamSlides} classNames={'translated-up teams'} numSlides={isMobile ? 2 : 3} />
                </Content>
            </section>

            <section>
                <Content>
                    <h2>{t('home.new-in')}</h2>
                    {isMobile ?
                        <img src={require('../assets/img/banners/mclaren_banner.svg').default} alt="mclaren" className={Style.homepageTeamBannersMobile} />
                        :
                        <img src={require('../assets/img/banners/mclaren_banner_deep.svg').default} alt="mclaren" className={Style.homepageTeamBanners} />
                    }
                    <ProductSlider 
                        filters={
                            {
                                teams: [ 'mclaren' ]
                            } 
                        } 
                        classNames={'new-in-swiper translated-up'}
                        numSlides={isMobile ? 2 : 4}
                        numPage={1}
                        productLimit={6}
                    />
                </Content>
            </section>

            <section>
                <Content>
                    {isMobile ?
                        <img src={require('../assets/img/banners/ferrari_banner.svg').default} alt="ferrari" className={Style.homepageTeamBannersMobile} />
                        :
                        <img src={require('../assets/img/banners/ferrari_banner_deep.svg').default} alt="mercedes" className={Style.homepageTeamBanners} />
                    }
                    <ProductSlider 
                        filters={
                            {
                                teams: [ 'ferrari' ]
                            } 
                        } 
                        classNames={'new-in-swiper translated-up'}
                        numSlides={isMobile ? 2 : 4}
                        numPage={1}
                        productLimit={6}
                    />
                </Content>
            </section>

            <section>
                <Content>
                    {isMobile ?
                        <img src={require('../assets/img/banners/red_bull_banner.svg').default} alt="redbull" className={Style.homepageTeamBannersMobile} />
                        :
                        <img src={require('../assets/img/banners/red_bull_banner_deep.svg').default} alt="redbull" className={Style.homepageTeamBanners} />
                    }
                    <ProductSlider 
                        filters={
                            {
                                teams: [ 'red_bull' ]
                            } 
                        } 
                        classNames={'new-in-swiper translated-up'}
                        numSlides={isMobile ? 2 : 4}
                        numPage={1}
                        productLimit={6}
                    />
                </Content>
            </section>

            {/* <section style={{paddingBottom: '60px'}}>
                <Content>
                    <h2>{t('home.new-in')}</h2>
                    <ProductSlider 
                        filters={
                            {
                                // headless_category: ['best_sellers']
                            } 
                        } 
                        classNames={'best-sellers-swiper'}
                        numSlides={isMobile ? 2 : 4}
                        numPage={1}
                        productLimit={6}
                    />
                    <ProductSlider 
                        filters={
                            {
                                // headless_category: ['best_sellers']
                            } 
                        } 
                        classNames={'best-sellers-swiper'}
                        numSlides={isMobile ? 2 : 4}
                        numPage={2}
                        productLimit={6}
                    />
                </Content>
            </section> */}
            <section style={{paddingBottom: '60px'}}>
                <Content>
                    <h2>{t('home.best-sellers')}</h2>
                    <ProductSlider 
                        filters={
                            {
                                headless_category: [ 'best_sellers' ]
                            } 
                        } 
                        classNames={'best-sellers-swiper'}
                        numSlides={isMobile ? 2 : 4}
                        numPage={1}
                        productLimit={6}
                    />
                </Content>
            </section>
            <section>
                <Reviews />
            </section>
            <section>
                <Content>
                    <ContentSwiper swiperSlides={driverSlides} classNames={'translated-up'} numSlides={isMobile ? 2 : 3} />
                </Content>
            </section>
            <section style={{paddingBottom: '60px'}}>
                <Content>
                    <h2>{t('home.about-us')}</h2>
                    <p>{t('home.our-story')}</p>
                </Content>
            </section>
        </>
    );
}