import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../cart/cartSlice";
import Style from './PromoCode.module.css';


export default function PromoCode() {

    const {t, i18n} = useTranslation('common');
    const cartData = useSelector(state => state.cart);
    const dispatch = useDispatch();

    const [promoCode, setPromoCode] = useState('');
    const [promoCodeStatus, setPromoCodeStatus] = useState('');

    // Apply promo code
    const applyPromo = () => {
        console.log('Applying promo code', promoCode);
        setPromoCodeStatus(t('cart.applying-promo-code'));

        // update cart
        let newCartData = {
            ...cartData,
            promoCode: promoCode
        }

        dispatch(updateCart({cartData: newCartData}));
    }

    // Promo code applied
    useEffect(() => {
        console.log('Cart data updated in coupon ', cartData);
        if (cartData.promoCodeError) {
            // sanitize error message as it's reflected
            let error = cartData.promoCodeError.replace(/<[^>]*>?/gm, '');

            console.log(error);
            //setPromoCodeStatus(t('cart.promo-code-error'));
            setPromoCodeStatus(error);

            setTimeout(() => {
                setPromoCodeStatus('');
            }, 3000);

            return;
        }
        
        if (cartData.promoCodeSuccess) {
            setPromoCodeStatus(t('cart.promo-code-applied'));

            setTimeout(() => {
                setPromoCodeStatus('');
            }, 3000);
        }
    }, [cartData])

    return (
        <>
            <div className={Style.promoCode}>
                <input type="text" placeholder={t('cart.enter-promo-code')} value={promoCode} onChange={(e) => {setPromoCode(e.target.value)}} />
                <button onClick={applyPromo}>{t('cart.apply-promo-code')}</button>
            </div>
            <span className={Style.status}>{promoCodeStatus}</span>
        </>
    );
}