import React, {useRef} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { useSelector } from 'react-redux';
import 'swiper/swiper.min.css';
import './ContentSwiper.css';


export default function ContentSwiper({swiperSlides, numSlides, classNames, autoLoop}) {

    const {isMobile} = useSelector(state => state.mobile);
    const swiperRef = useRef(null);
    SwiperCore.use([Autoplay])


    if (!numSlides) {
        numSlides = 3;
    }

    classNames += ' contentSwiper' + (isMobile ? ' mobile' : '');

    const handlePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    }

    const handleNext = () => {
        console.log('next');
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    }

    return (
        <>
            <Swiper
                spaceBetween={isMobile ? 5 : 15}
                slidesPerView={numSlides}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                ref={swiperRef}
                className={classNames}
                autoplay={autoLoop ? {delay: 5000} : false}
                loop={true}
            >
                {swiperSlides.map((slide, index) => (
                    <SwiperSlide key={index}>
                        {slide}
                    </SwiperSlide>
                ))}
                {swiperSlides.length > numSlides &&
                    <>
                        <a className="header-prev" onClick={handlePrev}><img src={require('../../assets/img/arrow_left.svg').default} alt="left-arrow" /></a>
                        <a className="header-next" onClick={handleNext}><img src={require('../../assets/img/arrow_right.svg').default} alt="right-arrow" /></a>
                    </>
                }
            </Swiper>
        </>
    );
}