import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import client from '../../services/woocom.js';


export function getCartFromLocalStorage() {

    // get cart from local storage if user is not logged in
    if (localStorage.getItem('cart')) {
        console.log('getting cart from local storage');
        let cart = JSON.parse(localStorage.getItem('cart'));
        console.log('Cart from local storage', cart);

        return cart;
    }

    // else return empty cart state
    else {
        return {
            lineItems: [],
            status: 'idle',
            subTotal: 0,
            tax: 0,
            taxTitle: '',
            shipping: 0,
            selectedShippingRate: '',
            selectedTableShippingRate: '',
            shippingTitle: '',
            total: 0,
            promoCode: '',
            promoCodeError: '',
            promoCodeSuccess: '',
            discount: 0
        }
    }
}


export const updateCart = createAsyncThunk('cart/updateCart', async (cartData) => {

    const response = await client.post('/wc-cart', cartData);
    return response.data;
})


export const updateCartWithShippingRate = createAsyncThunk('shipping/updateCartWithShippingRate', async (stripeEvent, { getState, dispatch }) => {

    const shippingRates = await getState().shippingRates.shippingRates;
    const selectedShippingRate = shippingRates[0].id;

    const cart = await getState().cart;

    console.log('updated cart with new rates', selectedShippingRate);

    // update cart total
    let cartData = {
        ...cart,
        selectedShippingRate: selectedShippingRate
    }

    const response = await client.post('/wc-cart', {cartData: cartData});

    return response.data;
})


export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        lineItems: [],
        status: 'idle',
        subTotal: 0,
        tax: 0,
        taxTitle: '',
        shipping: 0,
        selectedShippingRate: '',
        selectedTableShippingRate: '',
        shippingTitle: '',
        total: 0,
        promoCode: '',
        promoCodeError: '',
        promoCodeSuccess: '',
        discount: 0
    },
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(updateCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCart.fulfilled, (state, action) => {
                state.lineItems = action.payload.lineItems;
                state.tax = action.payload.tax;
                state.subTotal = action.payload.subTotal;
                state.shipping = action.payload.shipping;
                state.selectedShippingRate = action.payload.selectedShippingRate;
                state.selectedTableShippingRate = action.payload.selectedTableShippingRate;
                state.total = action.payload.total;
                state.promoCode = action.payload.promoCode;
                state.promoCodeError = action.payload.promoCodeError;
                state.promoCodeSuccess = action.payload.promoCodeSuccess;
                state.discount = action.payload.discount;
                state.loading = false;

                localStorage.setItem('cart', JSON.stringify(state));
            })
            .addCase(updateCartWithShippingRate.fulfilled, (state, action) => {
                state.lineItems = action.payload.lineItems;
                state.tax = action.payload.tax;
                state.subTotal = action.payload.subTotal;
                state.shipping = action.payload.shipping;
                state.selectedShippingRate = action.payload.selectedShippingRate;
                state.selectedTableShippingRate = action.payload.selectedTableShippingRate;
                state.total = action.payload.total;
                state.promoCode = action.payload.promoCode;
                state.promoCodeError = action.payload.promoCodeError;
                state.promoCodeSuccess = action.payload.promoCodeSuccess;
                state.discount = action.payload.discount;
                state.loading = false;

                localStorage.setItem('cart', JSON.stringify(state));
            })
    }
})

export const { updatecartEventHandled } = cartSlice.actions;
export default cartSlice;