import React, { useState, useEffect } from "react";
import Style from "./LanguageModal.module.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageSelect from "./LanguageSelect";


export default function LanguageModal() {

  const {localeCode} = useSelector(state => state.internationalisation);
  const {t, i18n} = useTranslation('common');
  const [isOpen, setOpen] = useState(false);
  const [flagImage, setFlagImage] = useState(null);
  

  useEffect(() => {
    if (!localeCode) {
      return;
    }

    // set flag image
    let imageName = (localeCode + '.svg').toLowerCase();

    // handle UK
    if (imageName == 'uk.svg') {
      imageName = 'gb.svg';
    }

    import('../../assets/img/region-flags/' + imageName)
      .then(image => {
        setFlagImage(image.default);
      })
      .catch(error => {
        console.log('Error loading flag image', imageName);
      });
  }, [localeCode])

  return(
    <>
      <a 
        className={Style.toggle} 
        onClick={() => setOpen(currentState => !currentState)}
        style={flagImage && {backgroundImage: `url(${flagImage})`}}
      ></a>

      <div className={`${Style.backdrop} ${(isOpen) ? Style.open : ''}`}></div>
      <div className={`${Style.modal} ${(isOpen) ? Style.open : ''}`}>
        <a onClick={() => setOpen(false)} className={Style.close}>
          <img src={require('../../assets/img/cross.svg').default} alt="close" />
        </a>
        <h2 style={{marginBlock: "0px"}}>{t('preferences.title')}</h2>

        <LanguageSelect />
      </div>
    </>
  );
}