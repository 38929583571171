import React, { useEffect, useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import client from '../../services/woocom.js';
import { useSelector } from "react-redux";


export default function StripePaymentWrapper({children}) {

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [options, setOptions] = useState({});
    const { currentCurrency } = useSelector(state => state.internationalisation);
    const { total } = useSelector(state => state.cart);

    let paymentIntentURL = '';

    // if test mode
    if (process.env.REACT_APP_TEST_MODE == 'true') {
        paymentIntentURL = process.env.REACT_APP_WOOCOM_API_BASE_URL_TEST + 'stripe/payment_intent';
    } else {
        paymentIntentURL = process.env.REACT_APP_WOOCOM_API_BASE_URL + 'stripe/payment_intent';
    }

    useEffect(() => {

        // set options
        setOptions({
            mode: 'payment',
            amount: Math.round(total * 100),
            currency: currentCurrency.toLowerCase(),
            appearance: {
                variables: {
                    borderRadius: '20px' ,
                }
            }
        });

        // set promise
        if (process.env.REACT_APP_STRIPE_TEST_MODE == 'true') {
            console.log('Stripe test mode');
            setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY));
        }
        else {
            console.log('Stripe live payment');
            setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY));
        }

    }, [currentCurrency]);


    // get stripe client secret
    const getClientSecret = async (total, currency, indempotencyKey) => {

        try {
            const response = await client.post(paymentIntentURL, {amount: total, currency: currency, indempotencyKey: indempotencyKey});
            const secret = response.data.data.clientSecret;
    
            setClientSecret(secret);
    
            return secret;
        } catch (err) {
            console.log(err);
            throw err; // Rethrow the error to be caught by the caller
        }
    }


    const childrenWithProps = React.Children.map(children, (child) => {
        return React.cloneElement(child, { 
            clientSecret, getClientSecret,
            options, setOptions
        });
    });

    return (
        <>
            {stripePromise && options ? 
                <Elements stripe={stripePromise} options={options}  >
                    {childrenWithProps}
                </Elements>
            :
                <p>Loading...</p>
            }
        </>
    );
}


// // Handle Stripe Card Payment
// export const handleStripeCardPayment = async (fields, stripe, elements, CardElement) => {

//     if (!stripe || !elements) {
//         console.log('stripe or elements not ready');
//         return;
//     }

//     // handle stripe payment
//     const result = await stripe.confirmCardPayment(
//         elements._commonOptions.clientSecret.clientSecret,
//         {
//             payment_method: {
//                 card: elements.getElement(CardElement),
//                 billing_details: {
//                     name: fields.billing_first_name + ' ' + fields.billing_last_name,
//                 },
//             }
//         }
//     ); 

//     console.log(result);
    
//     // payment error
//     if (result.error) {
//         return {error: result.error};
//     }

//     // success
//     else if (result.paymentIntent.status === 'succeeded') {
//         return {
//             success: true,
//             paymentID: result.paymentIntent.id,
//             error: null
//         };
//     }

// }