import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Collapsible from "../collapsible/Collapsible";
import Style from "./Faqs.module.css";

export default function Faqs() {

  const {t, i18n, ready} = useTranslation('common');
  const [faqs, setFAQs] = useState([]);

  useEffect(() => {

    // Bail if no translations are loaded
    if (!ready) {
      return;
    }

    const formattedFaqs = Object.keys(t('questions-and-answers', { returnObjects: true })).map((key) => {

      // Ignore solo answers
      if (!key.startsWith('question-')) {
        return;
      }

      // Fetch question and answer pairs
      const number = key.split('-')[1];
      const question = t(`questions-and-answers.question-${number}`);
      const answer = t(`questions-and-answers.answer-${number}`);
      return [question, {__html: answer}];
    
    }).filter((value) => !!value);
    setFAQs(formattedFaqs);

  }, [ready]);

  return(
    <>
      {faqs.map(([question, answer], index) => (
        <Collapsible classNames={Style.collapsible} key={index} title={question}>
          <div className={Style.answer} dangerouslySetInnerHTML={answer}>

          </div>
        </Collapsible>
      ))}
    </>
  );

}