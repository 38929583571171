import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function BillingAddress({order}) {

    const {billingAddress} = order;
    const {t, i18n} = useTranslation('common');

    return (
        <div>
            {billingAddress && 
                <>
                    <table>
                        <tr>
                            <td>{billingAddress.billingFirstName} {billingAddress.billingLastName}</td>
                        </tr>
                        {billingAddress.billingCompany &&
                            <tr>
                                <td>{billingAddress.billingFirstName}</td>
                            </tr>
                        }
                        <tr>
                            <td>{billingAddress.billingAddress1}</td>
                        </tr>
                        {billingAddress.billingAddress2 &&
                            <tr>
                                <td>{billingAddress.billingAddress2}</td>
                            </tr>
                        }
                        <tr>
                            <td>{billingAddress.billingCity}{billingAddress.billingPostcode &&
                                <span>, {billingAddress.billingPostcode}</span>
                            }</td>
                        </tr>
                        {billingAddress.billingState &&
                            <tr>
                                <td>{billingAddress.billingState}</td>
                            </tr>
                        }
                        <tr>
                            <td>{billingAddress.billingCountry}</td>
                        </tr>
                    </table>

                    <h4>{t('order-recieved.contact-details')}</h4>

                    <table>
                        <tr>
                            <td><strong>{t('order-recieved.phone')}:</strong> {billingAddress.billingPhone}</td>
                        </tr>
                        <tr>
                            <td><strong>{t('order-recieved.email')}:</strong> {billingAddress.billingEmail}</td>
                        </tr>
                    </table>
                </>
            }
        </div>
    );
}