export const localeData = [
    {
        "localeCode": "UK",
        "languageCode": "en",
        "regionName": "United Kingdom",
        "languageName": "English",
    },
    {
        "localeCode": "US",
        "languageCode": "en",
        "regionName": "United States",
        "languageName": "English",
    },
    {
        "localeCode": "AU",
        "languageCode": "en",
        "regionName": "Australia",
        "languageName": "English",
    },
    {
        "localeCode": "NZ",
        "languageCode": "en",
        "regionName": "New Zealand",
        "languageName": "English",
    },
    {
        "localeCode": "NL",
        "languageCode": "en",
        "regionName": "Nederland",
        "languageName": "Nederland",
    },
    {
        "localeCode": "EN-NL",
        "languageCode": "en",
        "regionName": "Netherlands",
        "languageName": "English",
    },
    {
        "localeCode": "FR",
        "languageCode": "fr",
        "regionName": "France",
        "languageName": "Français",
    },
    {
        "localeCode": "EN-FR",
        "languageCode": "en",
        "regionName": "France",
        "languageName": "English",
    },
    {
        "localeCode": "DE",
        "languageCode": "de",
        "regionName": "Deutschland",
        "languageName": "Deutsch",
    },
    {
        "localeCode": "EN-DE",
        "languageCode": "en",
        "regionName": "Germany",
        "languageName": "English",
    },
    {
        "localeCode": "ES",
        "languageCode": "es",
        "regionName": "España",
        "languageName": "Español",
    },
    {
        "localeCode": "EN-ES",
        "languageCode": "en",
        "regionName": "Spain",
        "languageName": "English",
    },
    {
        "localeCode": "PT",
        "languageCode": "pt",
        "regionName": "Portugal",
        "languageName": "Português",
    },
    {
        "localeCode": "EN-PT",
        "languageCode": "en",
        "regionName": "Portual",
        "languageName": "English",
    },
    {
        "localeCode": "IT",
        "languageCode": "it",
        "regionName": "Italia",
        "languageName": "Italiana",
    },
    {
        "localeCode": "EN-IT",
        "languageCode": "en",
        "regionName": "Italy",
        "languageName": "English",
    },
    {
        "localeCode": "IE",
        "languageCode": "en",
        "regionName": "Ireland",
        "languageName": "English",
    }
    
]