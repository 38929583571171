import React, { useState, useEffect } from "react";
import axios from 'axios';
import "./CookieNotice.css";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "../customCheckbox/CustomCheckbox";
import client from "../../services/woocom";


export default function CookieNotice() {

    const [consentPopupOpen, setConsentPopupOpen] = useState(false);
    const [moreInfoOpen, setMoreInfoOpen] = useState(false);
    const [preferenceAnalytics, setPreferenceAnalytics] = useState(true);
    const [preferenceMarketing, setPreferenceMarketing] = useState(true);
    const timeToOpen = 1500;
    const cookieExpire = 365;
    const cookieName = 'gnar-cookie-consent';
    const {t, i18n} = useTranslation('common');

    /**
     * Show consent popup if no cookie consent given
     */
    useEffect(() => {
        const cookieConsentGiven = document.cookie
            .split("; ")
            .find((row) => row.startsWith(cookieName));
    
        if (!cookieConsentGiven) {
            const openTimer = setTimeout(() => {
                setConsentPopupOpen(true);
            }, timeToOpen);
        
            return () => {
                clearTimeout(openTimer);
            };
        }
    }, []);

    /**
     * Accept all cookies
     */
    const acceptAll = () => {

        let consent = {
            analytics_storage: true,
            ad_storage: true,
            ad_user_data: true,
            ad_personalization: true
        }

        setCookie(consent);
        logConsent(consent);
        setConsentPopupOpen(false);
    }

    /**
     * Deny non-essential cookies
     */
    const denyNonEssential = () => {

        let consent = {
            analytics_storage: false,
            ad_storage: false,
            ad_user_data: false,
            ad_personalization: false
        }

        setCookie(consent);
        logConsent(consent);
        setConsentPopupOpen(false);
    }

    /**
     * Save preferences
     */
    const savePreferences = () => {

        let consent = {};

        if (preferenceAnalytics) {
            consent.analytics_storage = true;
        } else {
            consent.analytics_storage = false;
        }

        if (preferenceMarketing) {
            consent.ad_storage = true;
            consent.ad_user_data = true;
            consent.ad_personalization = true;
        } else {
            consent.ad_storage = false;
            consent.ad_user_data = false;
            consent.ad_personalization = false;
        }

        setCookie(consent);
        logConsent(consent);
        setConsentPopupOpen(false);
    }

    /**
     * Set cookie
     * 
     * @param {} consent 
     */
    const setCookie = (consent) => {

        let date = new Date();
        date.setTime(date.getTime() + (cookieExpire * 24 * 60 * 60 * 1000));
        document.cookie = `${cookieName}=${JSON.stringify(consent)}; expires=${date.toUTCString()}; path=/`;
    }

    /**
     * Log consent preferences
     * 
     * @param {} consent
     */
    const logConsent = (consent) => {
            
        if (!client) {
            return;
        }

        client.post('/gs-cookie-consent', {'consent': consent})
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <div id="gnar-consent-popup-outer" class={consentPopupOpen && 'active'}>
            <div class="inner">
                <div id="gnar-consent-popup">
                    <h2>{t('cookie-consent.title')}</h2>
                    <p>{t('cookie-consent.sub-title-text')}</p>
                    <div id="gnar-consent-collapsible" class={moreInfoOpen && 'active'}>
                        <div class="gs-cookie-info-cont">
                            <h4>{t('cookie-consent.essential-cookies')}</h4>
                            <p>{t('cookie-consent.essential-cookies-desc')}</p>
                        </div>
                        <div class="gs-cookie-info-cont">
                            <h4>{t('cookie-consent.analytics-cookies')}</h4>
                            <p>{t('cookie-consent.analytics-cookies-desc')}</p>
                            <CustomCheckbox label="" checked={preferenceAnalytics} onChange={() => setPreferenceAnalytics(!preferenceAnalytics)} term="analytics" />
                        </div>
                        <div class="gs-cookie-info-cont">
                            <h4>{t('cookie-consent.marketing-cookies')}</h4>
                            <p>{t('cookie-consent.marketing-cookies-desc')}</p>
                            <CustomCheckbox label="" checked={preferenceMarketing} onChange={() => setPreferenceMarketing(!preferenceMarketing)} term="marketing" />
                        </div>
                    </div>
                    <div class="gs-button-cont">
                        {moreInfoOpen ? ( 
                            <>
                                <button id="deny-cookies" onClick={() => denyNonEssential()}>{t('cookie-consent.reject')}</button>
                                <button id="accept-cookies" onClick={() => acceptAll()}>{t('cookie-consent.accept-all')}</button>
                                <button id="save-preferences" onClick={() => savePreferences()}>{t('cookie-consent.save-preferences')}</button>
                            </>
                        ): (
                            <>
                                <button id="more-info-btn" onClick={() => setMoreInfoOpen(!moreInfoOpen)}>{t('cookie-consent.more-info')}</button>
                                <button id="accept-cookies" onClick={() => acceptAll()}>{t('cookie-consent.accept-all')}</button>
                            </>
                        )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}