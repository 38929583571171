import React from "react";
import Theme from '../theme.module.css';

function Content({children, classNames}) {

    return(
        <div className={`${Theme.contentWrapper} ${classNames}`}>
            <div className={Theme.content}>
                {children}
            </div>
        </div>
    );
}

export default Content;