import React, { useRef } from 'react';
import client from '../../services/woocom';
import { useSelector } from 'react-redux';
import SingleCatalogProduct from '../productCatalog/SingleCatalogProduct';
import { Swiper, SwiperSlide } from 'swiper/react';
import './ProductSlider.css';


export default function ProductSliderRelated({sliderProducts, classNames, numSlides}) {

    const {isMobile} = useSelector(state => state.mobile);
    const swiperRef = useRef(null);

    classNames += ' product-swiper-related';
    
    const handlePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    }

    const handleNext = () => {
        console.log('next');
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    }


    return (
            <div className={`${isMobile && 'mobile'} productSlider`}>
            {Array.isArray(sliderProducts) && sliderProducts.length > 0 ? (
                <div>
                    <Swiper
                        spaceBetween={isMobile ? 5 : 15}
                        slidesPerView={numSlides}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        ref={swiperRef}
                        className={classNames}
                    >
                        {sliderProducts.map((product, index) => (
                            <SwiperSlide key={index}>
                                <SingleCatalogProduct key={index} product={product} />
                            </SwiperSlide>
                        ))}
                        <a className="header-prev" onClick={handlePrev}><img src={require('../../assets/img/arrow_left.svg').default} alt="left-arrow" /></a>
                        <a className="header-next" onClick={handleNext}><img src={require('../../assets/img/arrow_right.svg').default} alt="right-arrow" /></a>
                    </Swiper>
                </div>
            ):(
                <></>
            )}
        </div>
    ); 
}