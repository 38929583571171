import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux"
import { CurrencyFormat } from "../internationalisation/internationalisation";
import Theme from "../../theme.module.css";
import Style from "./OrderItemSingle.module.css";
import { useTranslation } from "react-i18next";

export default function OrderItemSingle({lineItem}) {

    const {t, i18n} = useTranslation('common');
    const {currentCurrency, pricesInclusiveTax} = useSelector(state => state.internationalisation);
    const {taxRate} = useSelector(state => state.shippingRates);
    const [displayLineTotal, setDisplayLineTotal] = useState(lineItem.line_total);
    
    useEffect(() => {

        if (!lineItem.total) {
            return;
        }

        let newLineTotal = parseFloat(lineItem.total);

        if (pricesInclusiveTax && parseFloat(lineItem.tax) > 0) {
            newLineTotal = parseFloat(newLineTotal + parseFloat(lineItem.tax));
        }

        setDisplayLineTotal(newLineTotal);
    }, [lineItem])

    return (
        <div className={`${Theme.flexRow} ${Style.orderItem}`}>
            <div className={`${Style.image} ${Theme.productImage}`} style={{backgroundImage: "url(" + lineItem.image + ")"}}>

            </div>
            <div className={Style.details}>
                <p className={Style.name}>{lineItem.name}</p>
                <p className={Style.qty}>{t('cart.quantity')}: {lineItem.quantity}</p>
                <p className={Style.total}>{CurrencyFormat(displayLineTotal, currentCurrency)}</p>
            </div>
        </div>
    );
}