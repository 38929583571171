import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './header/Header';
import Footer from './footer/Footer';
import Home from '../pages/Home';
import Catalog from '../pages/ProductCatalog';
import MyAccount from "../pages/MyAccount";
import Theme from '../theme.module.css';
import ProductSingle from "../pages/ProductSingle";
import Cart from "../pages/Cart";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Checkout from "../pages/Checkout";
import PopUp from "../features/ui/PopUp";
import Login from "../features/user/LoginForm";
import OrderRecieved from "../pages/OrderRecieved";
import './layout.css';
import Privacy from "../pages/Privacy";
import Terms from "../pages/Terms";
import Returns from "../pages/Returns";
import Contact from "../pages/Contact";
import CookieNotice from "../features/cookieNotice/CookieNotice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import ShippingInformation from "../pages/ShippingInformation";
import FBTracking from "../services/facebook-tracking";
import NotFound from "../pages/NotFound";
import Questions from "../pages/Questions";


export default function Layout() {

    const location = useLocation();
    const [pageTitle, setPageTitle] = useState(null);
    const [pageDesc, setPageDesc] = useState(null);
    const {localeCode} = useSelector(state => state.internationalisation);
    const [mobileSearchBarActive, setMobileSearchBarActive] = useState(false);
    const {t, i18n} = useTranslation('common');

    // route change
    useEffect(() => {

        // add page name class
        const path = window.location.pathname.split('/')[1];
        document.body.classList = '';
        
        if (path) {
            document.body.classList.add(path);  
        }

        // add locale code class
        if (localeCode) {
            document.body.classList.add('locale-' + localeCode);
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });

        // FB page view event
        if (localeCode && localeCode !== 'EN') {
            FBTracking.pageView(localeCode);
        }

    }, [location, localeCode])
    
    // set page meta
    useEffect(() => {
        setPageTitle(t('home.meta-title'));
        setPageDesc(t('home.meta-desc'));
    }, [])

    return (
        <div>
            {pageTitle && pageDesc &&
                <Helmet>
                    <title>{pageTitle.toString()}</title>
                    <meta name="description" content={pageDesc.toString()} />
                </Helmet>
            }
            <Header mobileSearchBarActive={mobileSearchBarActive} setMobileSearchBarActive={setMobileSearchBarActive} />
                <div className={`${Theme.page} ${mobileSearchBarActive ? Theme.pageMobileSearchActive : ''}`}>
                    <TransitionGroup class={getLocationClassName(location)}>
                        <CSSTransition 
                            key={location.key}
                            classNames="slide" 
                            timeout={1000}
                        >
                            <Routes location={location}>
                                <Route path="/" element={<Home />} />
                                <Route path="/catalog" element={<Catalog />} />
                                <Route path="/catalog/:category" element={<Catalog />} />
                                <Route path="/product/:slug" element={<ProductSingle />} />
                                <Route path="/my-account" element={<MyAccount />} />
                                <Route path="/cart" element={<Cart />} />
                                <Route path="/checkout" element={<Checkout />} />
                                <Route path="/order-recieved/:ordernumber" element={<OrderRecieved />} />
                                <Route path="/privacy-policy" element={<Privacy />} />
                                <Route path="/terms-of-use" element={<Terms />} />
                                <Route path="/delivery-information" element={<ShippingInformation />} />
                                <Route path="/returns" element={<Returns />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/faqs" element={<Questions />} />
                                <Route path="/*" element={<NotFound />} />
                            </Routes>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            <Footer />
            {/* <PopUp popUpName={'loginPopUp'}>
                <Login />
            </PopUp> */}
            <CookieNotice />
        </div>
    );
}

const getLocationClassName = (location) => {
    return location.pathname.replace('/', '');
};