import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthToken } from './auth';

let baseURL;

if (process.env.REACT_APP_TEST_MODE == 'true') {
    baseURL = process.env.REACT_APP_WOOCOM_API_BASE_URL_TEST
} else {
    baseURL = process.env.REACT_APP_WOOCOM_API_BASE_URL
}

const client = axios.create({
    baseURL: baseURL,
    withCredentials: true
})

// Requests
client.interceptors.request.use(
    (config) => {
        // Authorization header
        let authToken = getAuthToken();

        if (authToken) {
            config.headers['Authorization'] = `Bearer ${authToken}`;
        }

        const language = localStorage.getItem('GSHW_LANGUAGE');

        if (language) {
            config.headers['Accept-Language'] = language;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


export default client;