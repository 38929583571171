import React, {useState, useEffect} from "react";
import QuantitySelect from '../ui/QuantitySelect';
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../cart/cartSlice";
import Style from "./AddToCartForm.module.css";
import Theme from "../../theme.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import gs_dataLayer from '../../gs_modules/dataLayer';
import FBTracking from '../../services/facebook-tracking';


export default function AddToCartForm({selectedVariation, product, productAvailable}) {

    const {t, i18n} = useTranslation('common');
    const [quantity, setQuantity] = useState(1);
    const [cartAddEvent, setCartAddEvent] = useState(false);
    const [addToCartDisabled, setAddToCartDisabled] = useState(false);
    const [showCheckoutBtn, setShowCheckoutBtn] = useState(false);
    const [addToCartStockError, setAddToCartStockError] = useState(false);
    const cartData = useSelector(state => state.cart);
    const {localeCode} = useSelector(state => state.internationalisation);
    const dispatch = useDispatch();
    const {currentCurrency} = useSelector(state => state.internationalisation);


    function handleQuantityChange(newQuantity) {
        setQuantity(newQuantity);
        setAddToCartStockError(false);
    }

    function addToCart(e) {
        e.preventDefault();

        let newCartData;
        console.log(cartData);

        // get existing stock quantities from cart for this product
        let existingStock = 0;

        if (cartData.lineItems.length > 0) {
            cartData.lineItems.forEach((item) => {
                if (item.product_id == product.ID) {
                    existingStock += item.quantity;
                }
            });
        }

        console.log('selectedvariation ' + selectedVariation);

        // check quantity is equal to or less than stock
        if (selectedVariation) {
            // variable
            if (quantity + existingStock > product.variations[selectedVariation].stock) {
                setAddToCartStockError(product.variations[selectedVariation].stock);
                return false;
            }
        } else {
            // simple
            if (quantity + existingStock > product.stock) {
                setAddToCartStockError(product.stock);
                return false;
            }
        }

        // eventID (for facebook tracking)
        let eventID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        // variable
        if (selectedVariation) {
            newCartData = {
                ...cartData,
                event: 'addToCart',
                eventID: eventID,
                lineItems: [
                    ...cartData.lineItems,
                    {
                        variation_id: selectedVariation,
                        quantity: quantity
                    }
                ]
            }
        }

        // simple
        else {
            console.log('simple prod add');
            newCartData = {
                ...cartData,
                event: 'addToCart',
                eventID: eventID,
                lineItems: [
                    ...cartData.lineItems,
                    {
                        product_id: product.ID,
                        quantity: quantity
                    }
                ]
            }
        }

        console.log('cart submit');
        console.log(newCartData);

        // datalayer - add to cart
        gs_dataLayer.addToCart(product, currentCurrency, quantity);

        // facebook tracking - add to cart
        FBTracking.addToCart(localeCode, newCartData);

        dispatch(updateCart({cartData: newCartData}));

        setCartAddEvent(true);
        setAddToCartDisabled(true);
    }

    // cart add event timeout
    useEffect(() => {
        if (cartAddEvent) {
            setShowCheckoutBtn(true);

            setTimeout(() => {
                setCartAddEvent(false);
                setAddToCartDisabled(false);
            }, 3000);
        }
    }, [cartAddEvent]);

    // out of stock - disable add to cart button
    useEffect(() => {
        if (!productAvailable) {
            setAddToCartDisabled(true);
        } else {
            setAddToCartDisabled(false);
        }
    }, [productAvailable]);

    useEffect(() => {
        setAddToCartStockError(false);
    }, [selectedVariation]);

    return (
        <>
            <form onSubmit={addToCart} className={`${Style.addToCartForm} ${showCheckoutBtn ? Style.showCheckoutBtn : ''} ${!productAvailable && Style.outOfStock}`}>
                {!productAvailable && 
                    <span className={Style.outOfStockLabel}>{t('product.out-of-stock')}</span>
                }
                {addToCartStockError &&
                    <span className={Style.outOfStockLabel}>{t('product.not-enough-stock-1') + ' ' + addToCartStockError + ' ' + t('product.not-enough-stock-2')}</span>
                }
                <QuantitySelect initialValue={quantity} onQuantityChange={handleQuantityChange} />
                <div className={`${Style.addToCartBtnOuter} ${cartAddEvent ? Style.cartAddEvent : ''} addToCartBtnOuter`}>
                    <button type="submit" className={`${Style.addToCartBtn} addToCartBtn`} disabled={addToCartDisabled}>
                    </button>
                    <span>{cartAddEvent ? t('product.added-to-cart') : t('product.add-to-cart')}</span>
                </div>
                {showCheckoutBtn ?
                    <Link to="/cart" className={`${Style.addToCartBtn} ${Style.checkoutBtn} goToCheckoutBtn`}>{t('product.checkout-now')}</Link>
                    :
                    ''
                }
            </form>
        </>
    );
}