import React from "react";
import { CurrencyFormat } from "../internationalisation/internationalisation";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';


export default function OrderTotal() {

    const {total} = useSelector(state => state.cart);
    const {currentCurrency} = useSelector(state => state.internationalisation);
    const {t, i18n} = useTranslation('common');

    return (
        <div className="orderTotal">
            <table>
                <tr>
                    <td>{t('cart.total')}:</td>
                    <td>{total && CurrencyFormat(total, currentCurrency)}</td>
                </tr>
            </table>
        </div>
    );
}