import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import Content from "../layouts/Content";
import Style from "./LegalPage.module.css";


export default function ShippingInformation() {

    const {t, i18n} = useTranslation('common');
    const {localeCode} = useSelector(state => state.internationalisation);
    
    let translatedHTML;

    switch (localeCode) {
        case 'UK':
            translatedHTML = t('legal.shipping-information-uk');
            break;
        case 'US':
            translatedHTML = t('legal.shipping-information-us');
            break;
        case 'AU':
            translatedHTML = t('legal.shipping-information-au');
            break;
        case 'NZ':
            translatedHTML = t('legal.shipping-information-nz');
            break;
        default:
            translatedHTML = t('legal.shipping-information-eu');
            break;
    }


    translatedHTML = {__html:translatedHTML}

    return (
        <div className={Style.legalPageCont}>
            <Content>
                <h1>{t('legal.shipping-information-title')}</h1>
                <div dangerouslySetInnerHTML={translatedHTML}>

                </div>
            </Content>
        </div>
    );
}