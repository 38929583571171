import React from "react";
import { Link } from "react-router-dom";
import Content from "../layouts/Content";
import Style from "./NotFound.module.css";
import { useTranslation } from "react-i18next";

export default function NotFound() {

  const {t, i18n} = useTranslation('common');

  return(
    <Content classNames={Style.notfound}>
      <h1>{t('not-found.title')}</h1>
      <Link className={Style.action} to="/">{t('not-found.action')}</Link>
    </Content>
  );
}