import React, { useState, useEffect, useRef } from "react";
import Style from './HeaderTop.module.css';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from 'swiper/react';


export default function NoticeBanner() {

    const {t, i18n} = useTranslation('common');
    const {isMobile} = useSelector(state => state.mobile);
    const {localeCode} = useSelector(state => state.internationalisation);
    const [bannerNotices, setBannerNotices] = useState([]);
    const swiperRef = useRef(null);

    useEffect(() => {

        if (!localeCode) {
            return;
        }

        let notices = [];

        switch (localeCode) {
            case 'US':
                notices = [
                    <p>{t('notice-banner.US-free-shipping')}</p>,
                    <p>{t('notice-banner.US-reduced-shipping')}</p>,
                    <p>{t('notice-banner.US-reduced-shipping-2')}</p>,
                    <p>{t('notice-banner.US-reduced-shipping-3')}</p>
                ];
                break;

            case 'UK':
                notices.push(
                    <p>{t('notice-banner.UK-free-shipping')}</p>
                )
                break;

            case 'NZ':
                notices.push(
                    <p>{t('notice-banner.NZ-free-shipping')}</p>
                )
                break;

            case 'AU':
                notices.push(
                    <p>{t('notice-banner.AU-free-shipping')}</p>
                )
                break;
        }

        setBannerNotices(notices);

    }, [localeCode])


    return (
        <>
        {localeCode && bannerNotices &&
            <div className={`${Style.headerTop} ${Style.noticeBanner} ${isMobile && Style.mobile}`}>
                {Array.isArray(bannerNotices) && bannerNotices.length > 0 ? (
                    <Swiper
                        spaceBetween={5}
                        slidesPerView={1}
                        autoplay={{delay: 5000}}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        ref={swiperRef}
                    >
                        {bannerNotices.map((notice, index) => (
                            <SwiperSlide key={index}>
                                <div style={{width: "100%"}}>
                                    {notice}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    bannerNotices[0]
                )}
            </div>
        }
        </>
    );
}