import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { CurrencyFormat } from "../internationalisation/internationalisation";
import { useTranslation } from "react-i18next";


export default function OrderSubTotals({order}) {

    const {t, i18n} = useTranslation('common');
    const {currentCurrency, pricesInclusiveTax} = useSelector(state => state.internationalisation);
    const [displayShippingTotal, setDisplayShippingTotal] = useState(order.totalShipping);
    const [displaySubtotal, setDisplaySubtotal] = useState(order.subtotal);

    // Set display shipping total
    useEffect(() => {

        if (!order.totalShipping || !order.totalTax) {
            return;
        }

        let newShippingTotal = parseFloat(order.totalShipping);
        let lineItemTax = 0;

        if (pricesInclusiveTax && parseFloat(order.totalTax) > 0) {

            // Line item tax
            order.lineItems.forEach((lineItem, index) => {
                lineItemTax = parseFloat(lineItemTax) + parseFloat(lineItem.tax);
            });

            // Shipping tax
            let shippingTax = parseFloat(order.totalTax) - lineItemTax;

            // Shipping total
            newShippingTotal = parseFloat(newShippingTotal) + parseFloat(shippingTax);

        }

        setDisplayShippingTotal(newShippingTotal);
    }, [order])

    // Set display subtotal
    useEffect(() => {

        if (!order.lineItems || !order.totalTax) {
            return;
        }

        let newSubtotal = parseFloat(order.subtotal);
        let lineItemTax = 0;

        if (pricesInclusiveTax && parseFloat(order.totalTax) > 0) {

            // Line item tax
            order.lineItems.forEach((lineItem, index) => {
                lineItemTax = parseFloat(lineItemTax) + parseFloat(lineItem.tax);
            });

            newSubtotal = parseFloat(newSubtotal) + parseFloat(lineItemTax);
        }

        setDisplaySubtotal(newSubtotal);
    }, [order])


    return (
        <div className="orderSubTotals">
            <table>
                <tr>
                    <td>{t('totals.subtotal')}</td>
                    <td>{order.subtotal && CurrencyFormat(displaySubtotal, currentCurrency)}</td>
                </tr>
                <tr>
                    <td>{t('totals.shipping')}</td>
                    <td>{order.totalShipping && CurrencyFormat(displayShippingTotal, currentCurrency)}</td>
                </tr>
                <tr>
                    <td>{t('totals.tax')} {pricesInclusiveTax && <span className="taxIncTax">{t('cart.inc-tax')}</span>}</td>
                    <td>{order.totalTax && CurrencyFormat(order.totalTax, currentCurrency)}</td>
                </tr>
                {order.totalDiscount && order.totalDiscount !== "0" &&
                    <tr>
                        <td>{t('totals.discounts')}</td>
                        <td>{CurrencyFormat(order.totalDiscount, currentCurrency)}</td>
                    </tr>
                }
            </table>
        </div>
    );
}