import React from "react";
import Style from "./MyAccountHeader.module.css";
import { useSelector } from "react-redux";


export default function MyAccountHeader() {

    const {user, error, logged_in} = useSelector(state => state.user);
    const {isMobile} = useSelector(state => state.mobile);
    
    return (
        <div className={`${Style.cont} ${isMobile && Style.mobile}`}>
            <h1>My Account</h1>
            {user.data &&
                <div className={Style.userDetailsCont}>
                    <span>{user.data.user_email}</span>
                    <div className={Style.avatar}>
                        <img src={user.data.meta.avatar} alt="user-avatar" referrerpolicy="no-referrer" />
                    </div>
                </div>
            }
        </div>
    );
}