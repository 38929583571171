import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux"
import { CurrencyFormat } from "../internationalisation/internationalisation";
import Theme from "../../theme.module.css";
import Style from "./OrderItemSingle.module.css";


export default function OrderItemSingle({lineItem}) {

    const {currentCurrency, pricesInclusiveTax} = useSelector(state => state.internationalisation);
    const {taxRate} = useSelector(state => state.shippingRates);
    const [displayLineTotal, setDisplayLineTotal] = useState(lineItem.line_total);
    
    useEffect(() => {

        if (!taxRate || !lineItem.line_total) {
            return;
        }

        let newLineTotal = lineItem.line_total;
        console.log('got here', taxRate.rate, pricesInclusiveTax, lineItem.line_total);

        if (pricesInclusiveTax && taxRate.rate && taxRate.rate > 0) {
            let lineTax = parseFloat(lineItem.line_total / 100 * parseFloat(taxRate.rate));
            newLineTotal = lineItem.line_total + lineTax;
        }

        setDisplayLineTotal(newLineTotal);
    }, [lineItem, taxRate])

    return (
        <div className={`${Theme.flexRow} ${Style.orderItem}`}>
            <div className={`${Style.image} ${Theme.productImage}`} style={{backgroundImage: "url(" + lineItem.product_image + ")"}}>
            
            </div>
            <div className={Style.details}>
                <p className={Style.name}>{lineItem.product_name}</p>
                <p className={Style.qty}>Quantity: {lineItem.quantity}</p>
                <p className={Style.total}>{CurrencyFormat(displayLineTotal, currentCurrency)}</p>
            </div>
        </div>
    );
}