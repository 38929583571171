import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { updateCart, getCartFromLocalStorage } from './features/cart/cartSlice';
import { getUserData } from './features/user/userSlice';
import { getFilterTerms } from './features/productCatalog/productCatalogSlice';
import { useTranslation } from 'react-i18next';
import { getAuthToken } from './services/auth';
import { setIsMobile } from "./layouts/mobileSlice";
import { HelmetProvider } from "react-helmet-async";
import Theme from './theme.module.css';
import './animationClasses.css';
import './teamLocaleSpecific.css';
import { setSessionLocale } from "./features/internationalisation/internationalisationSlice.js";
import FacebookTracking from './services/facebook-tracking';


export default function App() {

  const dispatch = useDispatch();
  const { isMobile } = useSelector((state) => state.mobile);
  const { currentLanguage, localeCode } = useSelector(state => state.internationalisation);
  const { i18n } = useTranslation('common');
  const rootRef = useRef(null);
  const testMode = process.env.REACT_APP_TEST_MODE

  // render
  useEffect(() => {
    
    // init cart
    let cartData = getCartFromLocalStorage();
    dispatch(updateCart({cartData: cartData})); 

    // try to get user if logged in
    let authToken = getAuthToken(); 

    if (authToken) {
      dispatch(getUserData());
    }

    // set locale
    dispatch(setSessionLocale());

    // mobile check
    mobileCheck();

    // get filter terms
    dispatch(getFilterTerms({taxonomies: 'pa_colour,drivers,teams,types'}));

  }, [dispatch]);

  // update locale
  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage, i18n]);

  useEffect(() => {
    console.log('isMobile is: ', isMobile);
  }, [isMobile]);


  const mobileCheck = () => {
    
    const checkIsMobile = () => {
        const screenWidth = rootRef.current.offsetWidth;
        dispatch(setIsMobile(screenWidth <= 768));
    };

    // Initial check
    checkIsMobile();

    // Listen for window resize events
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener when the component unmounts
    return () => {
        window.removeEventListener('resize', checkIsMobile);
    };
  }

  return (
    <div className={`${isMobile ? Theme.mobile : Theme.desktop} ${isMobile ? '' : 'desktop'} App`} ref={rootRef}>
      <HelmetProvider>
        <Router>
          <Layout>

          </Layout>
        </Router>
      </HelmetProvider>
    </div>
  );

}