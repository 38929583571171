// mobileSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMobile: false,
  deviceDetermined: false
};

export const mobileSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
      state.deviceDetermined = true;
    },
  },
});

export const { setIsMobile } = mobileSlice.actions;
export default mobileSlice;