import React from "react";
import { useTranslation } from 'react-i18next';
import Content from "../layouts/Content";
import Style from "./LegalPage.module.css";


export default function Terms() {

    const {t, i18n} = useTranslation('common');
    
    let translatedHTML = t('legal.terms-1');
    translatedHTML += t('legal.terms-2');
    translatedHTML += t('legal.terms-3');
    translatedHTML += t('legal.terms-4');
    translatedHTML += t('legal.terms-5');
    translatedHTML += t('legal.terms-6');
    translatedHTML += t('legal.terms-7');
    translatedHTML += t('legal.terms-8');
    translatedHTML += t('legal.terms-9');
    translatedHTML += t('legal.terms-10');
    translatedHTML += t('legal.terms-11');
    translatedHTML += t('legal.terms-12');
    translatedHTML += t('legal.terms-13');
    
    translatedHTML = {__html:translatedHTML}

    return (
        <div className={Style.legalPageCont}>
            <Content>
                <h1>{t('legal.terms-title')}</h1>
                <div dangerouslySetInnerHTML={translatedHTML}>

                </div>
            </Content>
        </div>
    );
}