import React from "react";
import { CurrencyFormat } from "../internationalisation/internationalisation";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


export default function OrderTotal({order}) {

    const {t, i18n} = useTranslation('common');
    const {currentCurrency} = useSelector(state => state.internationalisation);

    return (
        <div className="orderTotal">
            <table>
                <tr>
                    <td>{t('totals.total')}</td>
                    <td>{order.total && CurrencyFormat(order.total, currentCurrency)}</td>
                </tr>
            </table>
        </div>
    );
}